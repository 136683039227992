import React from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { Row, Container, Col } from 'react-bootstrap';

const GoBack = ({ t, to, force }) => {
  const history = useHistory();

  const onClickBack = () => {
    if (to === 'prev') {
      console.log(1);
      history.goBack();
      if (force === true) {
        history.goBack();
      }
    } else {
      console.log(2);
      history.push(to ? to : '/');
    }
  };

  return (
    <div className="w-100">
      <div className="go_back py-4">
        <div className="button_wrap" onClick={() => onClickBack()}>
          <div className="go_back_button"></div>
        </div>
        <h2 className="big_text">{t('Go Back')}</h2>
      </div>
    </div>
  );
};

export default withNamespaces()(GoBack);
