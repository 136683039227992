export const FETCH_TODAYS_SCREENINGS = 'FETCH_TODAYS_SCREENINGS';
export const FETCH_TODAYS_SCREENINGS_SUCCESS = 'FETCH_TODAYS_SCREENINGS_SUCCESS';
export const FETCH_TODAYS_SCREENINGS_ERROR = 'FETCH_TODAYS_SCREENINGS_ERROR';

export const FETCH_CREATE_OWN_SHOW = 'FETCH_CREATE_OWN_SHOW';
export const FETCH_CREATE_OWN_SHOW_SUCCESS = 'FETCH_CREATE_OWN_SHOW_SUCCESS';
export const FETCH_CREATE_OWN_SHOW_ERROR = 'FETCH_CREATE_OWN_SHOW_ERROR';

export const FETCH_COMING_SOON = 'FETCH_COMING_SOON';
export const FETCH_COMING_SOON_SUCCESS = 'FETCH_COMING_SOON_SUCCESS';
export const FETCH_COMING_SOON_ERROR = 'FETCH_COMING_SOON_ERROR';

export const FETCH_FORMAT_LIST = 'FETCH_FORMAT_LIST';
export const FETCH_FORMAT_LIST_SUCCESS = 'FETCH_FORMAT_LIST_SUCCESS';
export const FETCH_FORMAT_LIST_ERROR = 'FETCH_FORMAT_LIST_ERROR';

export const FETCH_LANGUAGE_LIST = 'FETCH_LANGUAGE_LIST';
export const FETCH_LANGUAGE_LIST_SUCCESS = 'FETCH_LANGUAGE_LIST_SUCCESS';
export const FETCH_LANGUAGE_LIST_ERROR = 'FETCH_LANGUAGE_LIST_ERROR';

export const FETCH_FESTIVAL_LIST = 'FETCH_FESTIVAL_LIST';
export const FETCH_FESTIVAL_LIST_SUCCESS = 'FETCH_FESTIVAL_LIST_SUCCESS';
export const FETCH_FESTIVAL_LIST_ERROR = 'FETCH_FESTIVAL_LIST_ERROR';

export const FETCH_BANNERS = 'FETCH_BANNERS';
export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_ERROR = 'FETCH_BANNERS_ERROR';

export const SEARCH_MOVIE = 'SEARCH_MOVIE';
export const SEARCH_MOVIE_SUCCESS = 'SEARCH_MOVIE_SUCCESS';
export const SEARCH_MOVIE_ERROR = 'SEARCH_MOVIE_ERROR';

export const QUICK_BOOK = 'QUICK_BOOK';
export const QUICK_BOOK_MOVIE_SUCCESS = 'QUICK_BOOK_MOVIE_SUCCESS';
export const QUICK_BOOK_MOVIE = 'QUICK_BOOK_MOVIE';
export const QUICK_BOOK_MOVIE_ERROR = 'QUICK_BOOK_MOVIE_ERROR';

export const QUICK_BOOK_LANG_SUCCESS = 'QUICK_BOOK_LANG_SUCCESS';
export const QUICK_BOOK_FORMAT_SUCCESS = 'QUICK_BOOK_FORMAT_SUCCESS';
export const QUICK_BOOK_CINEMA_SUCCESS = 'QUICK_BOOK_CINEMA_SUCCESS';
export const QUICK_BOOK_DATE_SUCCESS = 'QUICK_BOOK_DATE_SUCCESS';
export const QUICK_BOOK_TIME_SUCCESS = 'QUICK_BOOK_TIME_SUCCESS';
export const QUICK_BOOK_ERROR = 'QUICK_BOOK_ERROR';

export const FETCH_FILTERS = 'FETCH_FILTERS';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_ERROR = 'FETCH_FILTERS_ERROR';
export const SET_FILTER = 'SET_FILTER';
export const SET_QB_STAGE = 'SET_QB_STAGE';

export const FETCH_MINI_FESTIVALS = 'FETCH_MINI_FESTIVALS';
export const FETCH_MINI_FESTIVALS_SUCCESS = 'FETCH_MINI_FESTIVALS_SUCCESS';
export const FETCH_MINI_FESTIVALS_ERROR = 'FETCH_MINI_FESTIVALS_ERROR';
export const SET_SELECTED_MINI_FESTIVAL = 'SET_SELECTED_MINI_FESTIVAL';
