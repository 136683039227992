export const getValueFromGlobalOptions = (globalOptions, key) => {
  if (Array.isArray(globalOptions)) {
    let obj = globalOptions.find((x) => x.go_key === key);

    if (obj) {
      return obj['go_value'];
    }
  }

  return null;
};
