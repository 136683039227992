import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { setDefaults } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { BASEURL } from '../../config';

const Signage = ({ t }) => {
  console.log(`t`, t);
  const global = useSelector((state) => state.global);
  const { signage, image_change_time } = global;
  const signageUrl = signage.Records;
  const [defaultIndex, setdefaultIndex] = useState(0);
  const history = useHistory();

  useEffect(() => {}, []);

  const onChageVideo = () => {
    if (defaultIndex == signageUrl.length - 1) {
      setdefaultIndex(0);
    } else {
      setdefaultIndex(defaultIndex + 1);
    }
  };

  const onBookNewTicket = () => {
    history.push(`/movies`);
  };

  const onCollectTicket = () => {
    history.push(`/kiosk-collect-login`);
  };

  const Video = () => (
    <div className="fullscreen-bg">
      {signageUrl &&
        signageUrl.map((x, index) => {
          return (
            <>
              {x.video_url && defaultIndex == index && (
                <video
                  muted
                  autoPlay
                  key={index + 'sd'}
                  src={BASEURL + x.video_url}
                  preload={'auto'}
                  type={'video/mp4'}
                  className="fullscreen-bg__video"
                  onEnded={(e) => {
                    onChageVideo();
                  }}
                ></video>
              )}
              {x.image_url && defaultIndex == index && (
                <img
                  key={index + 'suod'}
                  src={BASEURL + x.image_url}
                  className="fullscreen-bg__video fadeIn"
                  onLoad={(e) => {
                    setTimeout(() => {
                      onChageVideo();
                    }, image_change_time);
                  }}
                ></img>
              )}
            </>
          );
        })}
    </div>
  );

  const is_on_demand_active = window.localStorage.getItem('is_on_demand_active');
  console.log({ is_on_demand_active });
  useEffect(() => {}, [is_on_demand_active]);

  return (
    <div>
      <Video />
      <div className="signage-buttons">
        <div className="huge_buttons">
          <button
            className="left_button hb text-uppercase"
            onClick={onCollectTicket}
          >
            {' '}
            {t('Collect your Ticket')}
          </button>
          <button className="right_button hb" onClick={onBookNewTicket}>
            {' '}
            {t('collect_or_book.BOOK A NEW SHOW')}{' '}
          </button>

          {is_on_demand_active === 'true' && (
            <button
              onClick={() => history.push('/on-demand')}
              className="right_button hb"
            >
              {t('On-demand-kiosk').toUpperCase()}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(Signage);
