import React, { useEffect, useState } from 'react';
import MovieCard from '../partials/MovieCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNowPlayingMovies } from '@store/movies/moviesActions';
import { withNamespaces } from 'react-i18next';
import cinemaBg from '@assets/imgs/cinema-banner.png';
import { useHistory } from 'react-router';
import { fetchCreateOwnShow } from '../../store/homepage/homepageActions';
import Spinner from './Spinner';
import ScrollArrows from '@components/partials/ScrollArrows';

const OnDemand = ({ t }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [createOwnShowCurrentPage, setCreateOwnShowCurrentPage] = useState(1);
  const [limit, setLimit] = useState(24);

  const paginationInterval = 9;

  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [paginationEndIndex, setPaginationEndIndex] = useState(
    paginationInterval - 1,
  );

  useEffect(() => {
    setPaginationEndIndex(paginationStartIndex + paginationInterval - 1);
  }, [paginationStartIndex]);

  useEffect(() => {
    dispatch(
      fetchCreateOwnShow({
        limit,
        currentPage: createOwnShowCurrentPage,
      }),
    );
  }, [dispatch, limit, createOwnShowCurrentPage]);

  const createOwnShowMovies = useSelector((state) => state.homepage.createOwnShow);

  const createOwnShowLastPage = useSelector(
    (state) => state.homepage.createOwnShowLastPage,
  );

  const moviesLoader = useSelector((state) => state.homepage.createOwnShowLoading);
  const global = useSelector((state) => state.global);

  const {
    cinema: { cinema_id },
    selected_language: { lang_id },
    dummy_image,
  } = global;

  const uniqueCreateOwnShowMovies = {
    ...createOwnShowMovies,
    data: [
      ...new Map(
        createOwnShowMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };

  const createOwnShowLoadMore = ({ callback }) => {
    setCreateOwnShowCurrentPage(createOwnShowCurrentPage + 1);
    setTimeout(() => callback(false), 750);
  };

  return (
    <>
      {uniqueCreateOwnShowMovies?.data?.length > 0 && (
        <ScrollArrows
          paginationStartIndex={paginationStartIndex}
          setPaginationStartIndex={setPaginationStartIndex}
          paginationInterval={paginationInterval}
          arrayLength={uniqueCreateOwnShowMovies?.data?.length}
        />
      )}
      <section className="cinema-hero-section">
        <article className="hero-img-section">
          <img src={cinemaBg} alt="Movie Banner" className="hero-img-1" />
        </article>
        <article className="hero-text justify-content-center">
          <section className="container-fluid container-xl">
            <div className="go_back">
              <div className="button_wrap" onClick={() => history.go(-1)}>
                <div className="go_back_button"></div>
              </div>
              <h2 className="big_text">{t('Go Back')}</h2>
            </div>
            <div className="row h-100 align-items-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto text-center">
                <p className="title">{t('On-demand-kiosk')}</p>
                <p className="msg">Listing of all On Demand Movies</p>
              </div>
            </div>
          </section>
        </article>
      </section>
      <div className="container-fluid">
        <section className="row pt-2">
          {uniqueCreateOwnShowMovies?.data?.length > 0 &&
            uniqueCreateOwnShowMovies.data.map(
              (m, i) =>
                i >= paginationStartIndex &&
                i <= paginationEndIndex && (
                  <MovieCard
                    m={m}
                    t={t}
                    key={m.movie_id}
                    lang_id={lang_id}
                    dummy_image={dummy_image}
                  />
                ),
            )}
          {moviesLoader && (
            <div className="text-center w-100">
              <Spinner
                animation="border"
                role="status"
                className="spinner spinner2"
              >
                <span className="sr-only">{t('Loading')}</span>
              </Spinner>
              <br />
              <h6>{t('movie_listing.Movies Loading')}</h6>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(OnDemand);
