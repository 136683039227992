import React, { useEffect, useState, useRef, useContext } from 'react';
import ScreeningCardNew from './ScreeningCardNew.jsx';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import arrowBack from '@assets/imgs/svgs/arrow-back.svg';
import arrowBackActive from '@assets/imgs/svgs/arrow-back-active.svg';
import MovieFooter from './MovieFooter';
import dummyPoster from '@assets/imgs/trolls.png';
import MovieHeader from '@components/partials/MovieHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getLangSpecificAttribute } from '@helper/Languages';
import SpecialInstructionsPopup from './SpecialInstructionsPopup.jsx';
import SpecialInstructionsPopupRegular from './SpecialInstructionsPopupRegular.jsx';

import {
  fetchMovieDetails,
  fetchMovieSchedules,
  setStage,
  setBookingType,
  setFromMoviePage,
  fetchScreeningTypes,
  fetchScheduleDetails,
} from '../../store/movies/moviesActions';
import { privateScreeningReserveSeat } from '../../store/seatLayout/seatLayoutActions';
import moment from 'moment';

const ScreeningType = ({ match, t }) => {
  const [back, setBack] = useState(false);

  const history = useHistory();
  // const [specialRequest, setSpecialRequest] = useState("");
  const [showPrivateScreenPopup, setShowPrivateScreenPopup] = useState(false);
  const [tempPrivateShowDetails, setTempPrivateShowDetails] = useState(null);
  const isPrivateShowClicked = useRef(false);
  const cinema = useSelector((state) => state.global.cinema);

  const onConfirmPrivateScreeningPopup = (specialRequest) => {
    // dispatch(setPrivateScreeningMessage(specialRequest));
    if (isPrivateShowClicked.current === false) {
      isPrivateShowClicked.current = true;
      console.log('private show reserved');
      dispatch(
        privateScreeningReserveSeat({
          screen_id: tempPrivateShowDetails.screen_id,
          history,
          mycinema_ss_d_id,
          category_id: 1,
          cinema_id: tempPrivateShowDetails.cinema_id,
          movie_id,
          content_lang_id,
          genre_id,
          isPrivate,
          specialRequest,
          cinema,
        }),
      );
    }
  };
  const { movie_id, content_lang_id, category_id, genre_id, mycinema_ss_d_id } =
    useParams();
  const { isPrivate } = match.params;
  // console.log("isPrivate :>> ", isPrivate);
  const dispatch = useDispatch();
  const [value, onChange] = useState(new Date());
  useEffect(() => {
    // dispatch(fetchMovieDetails({ movie_id, content_lang_id }));
    // dispatch(fetchMovieSchedules({ movie_id, category_id }));
    // dispatch(fetchOtherScreenings({ genre_id, movie_id }));
    dispatch(
      fetchScreeningTypes({
        mycinema_ss_d_id,
        payload: { stage: 2, show: null, history },
      }),
    );
    dispatch(fetchScheduleDetails({ mycinema_ss_d_id }));
  }, []);
  const movieDetails = useSelector((state) => state.movies.movieDetails);
  const [cardSelected, setCardSelected] = useState(false);
  // useEffect(() => {
  //   if (movieDetails && category_id == 2) {
  //     dispatch(fetchOtherScreenings({ genre_id: 2 }));
  //   }
  // }, [movieDetails, category_id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const screeningTypesLoading = useSelector(
    (state) => state.movies.screeningTypesLoading,
  );

  const screen = screeningTypes.find((screen) => screen.booking_type_id === 3);
  let isPrivateScreeninExist = screen?.isPrivateScreeninExist === 'Y' ?? false;

  useEffect(() => {
    if (
      (!screeningTypes || screeningTypes.length === 0) &&
      !screeningTypesLoading
    ) {
      // history.go(-1);
    }
  }, [screeningTypes]);
  // const mycinema_ss_d_id = useSelector(
  //   (state) => state.movies.myCinemaCreateShow.mycinema_ss_d_id
  // );
  const show = useSelector((state) => state.movies.show);
  const otherScreeningsMovies = useSelector(
    (state) => state.movies.otherScreenings,
  );
  const [isOpen, setOpen] = useState(false);
  const onSelectScreeningType = (type, showName) => {
    // note: param `type` is `booking_type_id`
    console.log('type :>> ', type);
    dispatch(setBookingType(type));
    if (type === 1 || type === 2) {
      dispatch(setFromMoviePage());
      history.push(`/create-show-seat-layout/${mycinema_ss_d_id}/${type}`);
    } else {
      setShowPrivateScreenPopup(true);
      console.log('showName :>> ', showName);
      let tempShow;
      if (showName) {
        tempShow = showName;
      } else {
        tempShow = show;
      }
      console.log('show :>> ', show);
      setTempPrivateShowDetails(tempShow);
      // dispatch(
      //   privateScreeningReserveSeat({
      //     screen_id: tempShow.screen_id,
      //     history,
      //     mycinema_ss_d_id,
      //     category_id: 1,
      //     cinema_id: tempShow.cinema_id,
      //     movie_id,
      //     content_lang_id,
      //     genre_id,
      //     isPrivate,
      //   })
      // );
    }
  };
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_name, iso_2, lang_id } = selected_language;
  useEffect(() => {
    if (!movieDetails) {
      // alert("hi");
      const spinner = document.getElementById('main-spinner');
      if (spinner) {
        spinner.style.display = 'flex';
        setTimeout(() => (spinner.style.display = 'none'), 1000);
      }
      // else {
      //   alert("hi2");
      //   spinner.style.display = "none";
      // }
    }
  }, [movieDetails]);

  const movieSchedules = useSelector((state) => state.movies.movieSchedules);
  const movieSchedulesLoading = useSelector(
    (state) => state.movies.movieSchedulesLoading,
  );
  const movieScheduleDetails = useSelector(
    (state) => state.movies.movieScheduleDetails,
  );
  const onChangeCalenderDate = (value) => console.log(value);
  const todaysScreeningsMovies = useSelector(
    (state) => state.homepage.todaysScreenings,
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedLanguage2, setSelectedLanguage2] = useState(null);
  const [selectedFormat2, setSelectedFormat2] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [peopleSvg, setPeopleSvg] = useState(true);
  const [loginSvg, setLoginSvg] = useState(true);
  useEffect(() => {
    if (movieScheduleDetails?.dateList?.length > 0) {
      setSelectedDate(movieScheduleDetails.dateList[0].ss_actual_start_date);
    }
  }, [movieScheduleDetails]);
  // const dateSlider = {
  //   arrows: true,
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 6,
  //   slidesToScroll: 6,
  // };
  const dateSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
  };
  const onChangeDate = (date) => {
    console.log('date :>> ', date);
    const { ss_actual_start_date } = date;
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        dateSchedule: ss_actual_start_date,
        cinema_id: selectedCinema?.cinema_id,
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
      }),
    );
    setSelectedDate(ss_actual_start_date);
  };
  const onChangeCinema = (cinema) => {
    const { cinema_id } = cinema;
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        cinema_id,
        dateSchedule: moment(selectedDate).format('YYYY-MM-DD'),
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
      }),
    );
    setSelectedCinema(cinema);
  };
  const onChangeLanguage = (language) => {
    const { lang_id } = language;

    setSelectedLanguage2(language);
    // setModalShow(false);
  };
  const onConfirmShowDetailsPopup = () => {
    setSelectedLanguage(selectedLanguage2);
    setSelectedFormat(selectedFormat2);
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        cinema_id: selectedCinema?.cinema_id,
        dateSchedule: moment(selectedDate).format('YYYY-MM-DD'),
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
      }),
    );
    setModalShow(false);
  };

  const onChangeFormat = (format) => {
    const { mf_id } = format;
    // dispatch(
    //   fetchMovieSchedules({
    //     movie_id,
    //     category_id,
    //     cinema_id: selectedCinema?.cinema_id,
    //     dateSchedule: moment(selectedDate).format("YYYY-MM-DD"),
    //     lang_id: selectedLanguage?.lang_id,
    //     mf_id,
    //   })
    // );
    setSelectedFormat2(format);
    // setModalShow(false);
  };
  // const [stage, setStage] = useState(1);
  // const stage = useSelector((state) => state.movies.stage);
  const stage = 2;
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [calenderDateSelected, setCalenderDateSelected] = useState(false);

  const movieSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      otherScreeningsMovies.length > 6 ? 6 : otherScreeningsMovies.length,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="">
      <MovieHeader />
      <div className="container-fluid container-xl header-space">
        <p className="mx-auto big-heading text-center pt-5">
          {t('Select Time of your Movie')}
        </p>
        <section className="row justify-content-center for-prev-step">
          {/* {stage !== 1 && ( */}
          {
            <div className="prev-step">
              <button
                className="blue-btn mx-auto mt-3 mt-xl-4 px-4 py-2"
                // onClick={() => setStage(stage - 1)}
                onClick={() => history.go(-1)}
                onMouseOver={() => setBack(true)}
                onMouseOut={() => setBack(false)}
              >
                <img
                  src={back ? arrowBackActive : arrowBack}
                  className="mr3"
                  alt=""
                />
                {/* Back */}
              </button>
            </div>
          }

          <div className="col-sm-9 mx-auto">
            <div className="steps">
              <div
                className={`step ticket ${
                  stage === 1
                    ? 'complete'
                    : stage === 2
                    ? 'complete'
                    : stage === 3
                    ? 'complete'
                    : ''
                }`}
              >
                {/* <img src={ticketRed} /> */}
              </div>
              <div
                className={`step screen ${
                  stage === 2 ? 'complete' : stage === 3 ? 'complete' : ''
                }`}
              >
                {/* <img src={ticketRed} /> */}
              </div>
              <div className={`step seat ${stage === 3 ? 'complete' : ''}`}>
                {/* <img src={ticketRed} /> */}
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      </div>
      <div className={`container-fluid pt-5 ${true ? 'movie-footer-space' : ''}`}>
        {screeningTypes.length > 0 && (
          <ScreeningCardNew
            screeningTypes={screeningTypes}
            isUnconfirmedAvailable={show?.isUnconfirmedAvailable}
            passedFunc={(type) => onSelectScreeningType(type)}
            setCardSelected={setCardSelected}
            cardSelected={cardSelected}
          />
        )}
      </div>

      <MovieFooter
        movie_title={
          getLangSpecificAttribute(
            movieDetails?.altMovieContent,
            lang_id,
            'mc_title',
          ) || movieDetails?.movie_title
        }
        image={
          getLangSpecificAttribute(
            movieDetails?.languageSpecificImages,
            lang_id,
            'artwork',
          ) || dummyPoster
        }
        lang={movieDetails?.lang_name}
        format={movieDetails?.mf_name}
        rating={movieDetails?.rating}
        genre={
          movieDetails?.genres_with_translations?.[0]?.[iso_2?.toLowerCase()] ||
          movieDetails?.genre
        }
        cinemaName={show?.cinema_name}
        cine_location={show?.cine_location}
        cine_address={show?.cine_address}
        date={show?.ss_start_date}
        time={show?.ss_start_show_time}
        showType={
          show?.showType === 2
            ? 'Unconfirmed'
            : show?.showType === 1
            ? 'Confirmed'
            : null
        }
        category_id={category_id}
      />
      {isPrivateScreeninExist ? (
        <SpecialInstructionsPopup
          showPrivateScreenPopup={showPrivateScreenPopup}
          onConfirmPrivateScreeningPopup={onConfirmPrivateScreeningPopup}
          closeFunc={() => {
            setShowPrivateScreenPopup(false);
            setCardSelected(false);
          }}
        />
      ) : (
        <SpecialInstructionsPopupRegular
          showPrivateScreenPopup={showPrivateScreenPopup}
          onConfirmPrivateScreeningPopup={onConfirmPrivateScreeningPopup}
          closeFunc={() => {
            setShowPrivateScreenPopup(false);
            setCardSelected(false);
          }}
        />
      )}
    </div>
  );
};

export default withNamespaces()(ScreeningType);
