import React, { useEffect, useContext } from 'react';
import i18n from '../plugins/i18n';
import { useHistory } from 'react-router-dom';
import {
  fetchGlobalSettings,
  getCinemas,
  chooseCinema,
  setKioskSelectedLanguage,
} from '../store/global/globalActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { getLangISOCode } from '@helper/formatting';

const SelectLanguage = ({ t, history }) => {
  const dispatch = useDispatch();

  const global = useSelector((state) => state.global);
  const { loading, error, globalSettings, globalOptions, selected_language } =
    global;
  const globalOptionsLanguages = globalOptions.find((go) => go.go_id === 30);
  let languages = [];
  if (globalOptionsLanguages && globalOptionsLanguages !== null) {
    const globalLanguages = JSON.parse(globalOptionsLanguages.go_value);
    languages = globalLanguages;
  }
  const onSelectLanguage = (language) => {
    let lang = language.iso_2
      ? language.iso_2.toLowerCase()
      : getLangISOCode(language.lang_name);
    i18n.changeLanguage(lang);
    // if (lang && lang != lng) {
    //   window.location.reload();
    // } else {
    //   history.push("/book-show");
    // }
    console.log('language :>> ', language);
    // if (language.lang_id === 1) {
    dispatch(setKioskSelectedLanguage(language));
    history.push('/book-show');
  };
  // useEffect(() => {
  //   if (selected_language !== null) {

  //   }
  // }, [selected_language, history]);
  return (
    <div className="parent">
      <div id="printableArea"></div>
      <div className="page2_container">
        {/* <Link to="/" className="page2_movie_header">
          <h2 className="small_text">{t("common.WELCOME TO")}</h2>
          <div className="logo2"></div>
        </Link> */}
        <div className="page2_body">
          <div className="choose_action">{t('movie_details.Choose Language')}</div>
          <div className="huge_buttons">
            {languages.map((language) => (
              <button
                className="left_button hb"
                key={language.lang_id}
                onClick={() => onSelectLanguage(language)}
              >
                {language.lang_name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(SelectLanguage);
