import {
  FETCH_GLOBAL,
  FETCH_GLOBAL_SUCCESS,
  FETCH_GLOBAL_ERROR,
  FETCH_GLOBAL_OPTIONS,
  FETCH_GLOBAL_OPTIONS_SUCCESS,
  FETCH_GLOBAL_OPTIONS_ERROR,
  FETCH_CINEMAS,
  FETCH_CINEMAS_SUCCESS,
  FETCH_CINEMAS_ERROR,
  FETCH_CURRENCY,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_ERROR,
  SELECT_CINEMA_SUCCESS,
  SELECT_CINEMA_ERROR,
  SET_KIOSK_SELECTED_LANGUAGE,
  SET_CHECKOUT_TIMER,
  SET_SIGNAGE_SCREEN,
  SET_SCREEN_TIME,
  SET_IMAGE_CHANGE_TIME,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
  SET_CUST_MOB_CODE,
  SET_BOOKING_TYPE,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  CINEMA_SETTINGS,
  CINEMA_SETTINGS_ERROR,
  DUMMY_IMAGE,
  SET_ON_DEMAND,
} from './globalTypes';
import MasterService from '../../ApiServices/MasterService';

export const fetchGlobalSettings = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GLOBAL });
    const { data } = await MasterService.GetGlobalSettings();
    window.localStorage.setItem('placeholder_image', data.Records[0].dummy_poster);
    let dummy_image = data.Records[0].dummy_poster;
    dispatch({ type: DUMMY_IMAGE, payload: dummy_image });
    console.log('fetchGlobalSettings -> data', data);
    dispatch({ type: FETCH_GLOBAL_SUCCESS, payload: data.Records[0] });
  } catch (error) {
    dispatch({ type: FETCH_GLOBAL_ERROR, payload: error.message });
  }
};

export const fetchGlobalOptions = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GLOBAL_OPTIONS });
    const { data } = await MasterService.GetGlobalOptions();
    console.log('data.Records', data.Records);
    dispatch({ type: FETCH_GLOBAL_OPTIONS_SUCCESS, payload: data.Records });
    const seat_release_time = data.Records.find(
      (go) => go.go_key === 'RELEASE_SEAT_BLOCKED_TIME',
    );
    if (seat_release_time !== undefined) {
      dispatch({
        type: SET_CHECKOUT_TIMER,
        payload: seat_release_time.go_value,
      });
    }
    const screenIdolTime = data.Records.find(
      (go) => go.go_key === 'SCREEN_IDOL_TIME',
    );
    console.log('screenIdolTime', screenIdolTime);
    if (screenIdolTime !== undefined) {
      console.log('SET_SCREEN_TIME', SET_SCREEN_TIME);
      dispatch({
        type: SET_SCREEN_TIME,
        payload: parseInt(screenIdolTime.go_value),
      });
    }
    const ImageChangeTime = data.Records.find(
      (go) => go.go_key === 'SIGNAGE_IMAGE_CHANGE_TIME',
    );
    console.log('ImageChangeTime', ImageChangeTime);
    if (ImageChangeTime !== undefined) {
      dispatch({
        type: SET_IMAGE_CHANGE_TIME,
        payload: parseInt(ImageChangeTime.go_value),
      });
    }
    const currency = data.Records.find((go) => go.go_key === 'CURRENCY');
    if (currency !== undefined) {
      dispatch({ type: FETCH_CURRENCY });
      const { data: currencyData } = await MasterService.GetCurrency({
        curr_id: currency.go_value,
      });

      if (currencyData && currencyData.status && currencyData.Records) {
        dispatch({
          type: FETCH_CURRENCY_SUCCESS,
          payload: currencyData.Records,
        });
      } else {
        dispatch({ type: FETCH_CURRENCY_ERROR });
      }
    }
  } catch (error) {
    dispatch({ type: FETCH_GLOBAL_OPTIONS_ERROR, payload: error.message });
  }
};
export const getCinemas = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CINEMAS });
    const { data } = await MasterService.GetCinemas();
    if (data && data.status && data.Records.length > 0) {
      dispatch({ type: FETCH_CINEMAS_SUCCESS, payload: data.Records });
    }
  } catch (error) {
    dispatch({ type: FETCH_CINEMAS_ERROR, payload: error.message });
  }
};

export const chooseCinema = (payload, history) => async (dispatch) => {
  try {
    dispatch({ type: SELECT_CINEMA_SUCCESS, payload });
    let mobCode = '+' + payload.cine_mobile_code;
    dispatch({ type: SET_CUST_MOB_CODE, payload: mobCode });
    localStorage.setItem('cinema', JSON.stringify(payload));
    // history.push("/");
  } catch (error) {
    dispatch({ type: SELECT_CINEMA_ERROR, payload: error.message });
  }
};

export const setKioskSelectedLanguage = (language) => async (dispatch) =>
  dispatch({ type: SET_KIOSK_SELECTED_LANGUAGE, payload: language });

export const getSignageScreen = (payload) => async (dispatch) => {
  const { data } = await MasterService.GetSignageScreen({ cinema_id: payload });
  console.log('data', data);
  dispatch({ type: SET_SIGNAGE_SCREEN, payload: data });
};

export const fetchCountries = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_COUNTRIES });
    const { data } = await MasterService.GetCountries();
    if (data && data.data.length > 0) {
      data.data = data.data.filter((x) => {
        if (x.country_mob_code != null) {
          x.country_mob_code = '+' + x.country_mob_code;
        }
        return x.country_mob_code != null;
      });

      dispatch({ type: FETCH_COUNTRIES_SUCCESS, payload: data.data });
    }
  } catch (error) {
    dispatch({ type: FETCH_COUNTRIES_ERROR, payload: error.message });
  }
};

export const setBookingType = (type) => async (dispatch) => {
  dispatch({ type: SET_BOOKING_TYPE, payload: type });
  console.log('type :>> ', type);
  localStorage.setItem(`kiosk_mode`, type);
};

export const setOnDemand = (e) => async (dispatch) => {
  let type = e.target.checked;
  dispatch({ type: SET_ON_DEMAND, payload: type });
  localStorage.setItem(`is_on_demand_active`, type);
};

export const login =
  ({ username, password, callback, setAuthError, history }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOGIN });
      const { data } = await MasterService.checkCinemaManagerAuth({
        username,
        password,
      });
      if (data && data.status) {
        dispatch({ type: LOGIN_SUCCESS });
        history.push(`/settings`);
      } else {
        setAuthError(true);
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_ERROR, payload: error.message });
    } finally {
      callback();
    }
  };

export const logout = () => async (dispatch) => dispatch({ type: LOGOUT });

export const fetchCinemaSettings = (payload) => async (dispatch) => {
  try {
    const { data } = await MasterService.fetchCinemaSettings(payload);
    console.log('data TEST', data);
    if (data && data.status && data.Records.length > 0) {
      dispatch({ type: CINEMA_SETTINGS, payload: data.Records[0] });
    }
  } catch (error) {
    dispatch({ type: CINEMA_SETTINGS_ERROR, payload: error.message });
  }
};
