import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNowPlayingMovies } from '@store/movies/moviesActions';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import MovieHeader from '@components/partials/MovieHeader';
import GoBack from '@components/partials/GoBack';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { Row, Container, Col, Spinner } from 'react-bootstrap';
import CSpinner from '@components/partials/Spinner';
import ListMovies from '@components/ListMovies';
import Schedules from '@components/Schedules';
import gridView from '../assets/imgs/svgs/grid-view.svg';
import listView from '../assets/imgs/svgs/list-view.svg';
import scheduleView from '../assets/imgs/svgs/schedule-view.svg';
import { fetchDateAllShows } from '@store/movies/moviesActions';
import moment from 'moment';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import { getLangSpecificAttribute } from '../helper/Languages';
import MovieCard from './partials/MovieCard';

import ScrollArrows from '@components/partials/ScrollArrows';
import dummyPosterStatic from '@assets/imgs/dummyPoster.png';

function Movies({ t }) {
  return (
    <div className="movie_listing_container">
      <MovieHeader t={t} />
      <MovieListings t={t} />
    </div>
  );
}

const MovieListings = ({ t }) => {
  const currentDate = moment().format('DD-MM-YYYY');
  const nextDate = moment().add(1, 'days').format('DD-MM-YYYY');
  const movieListsLoader = useSelector((state) => state.movies.movie_list_loader);
  const movieLists = useSelector((state) => state.movies.movie_list);

  const paginationInterval = 9;

  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [paginationEndIndex, setPaginationEndIndex] = useState(
    paginationInterval - 1,
  );

  useEffect(() => {
    setPaginationEndIndex(paginationStartIndex + paginationInterval - 1);
  }, [paginationStartIndex]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [display, setDisplay] = useState('grid');
  const [showSpinner, setShowSpinner] = useState(false);
  const global = useSelector((state) => state.global);
  const {
    cinema: { cinema_id },
    selected_language: { lang_id },
    dummy_image,
  } = global;
  useEffect(() => {
    dispatch(
      fetchNowPlayingMovies({
        cinema_id,
      }),
    );
    dispatch(
      fetchDateAllShows({
        selectedDate: currentDate,
        cinema_id: cinema_id,
      }),
    );
  }, [dispatch, cinema_id, currentDate]);

  const movies = useSelector((state) => state.movies.now_playing_movies);
  const moviesLoader = useSelector((state) => state.movies.movies_loader);

  const onClickGoBack = () => {
    history.push('/');
  };

  const onSelectFilter = (title, id) => {
    console.log(title, id);
    if (title == 'format') {
      dispatch(
        fetchDateAllShows({
          selectedDate: currentDate,
          cinema_id: 3,
          format_id: id,
        }),
      );
    }

    if (title == 'rating') {
      dispatch(
        fetchDateAllShows({
          selectedDate: currentDate,
          cinema_id: 3,
          rating_id: id,
        }),
      );
    }

    if (title == 'refresh') {
      dispatch(
        fetchDateAllShows({
          selectedDate: currentDate,
          cinema_id: 3,
        }),
      );
    }

    console.log(title);
  };
  const GridView = () => {
    return (
      <div>
        <h2 className="text-center pt-3 font-weight-bold f-blue">
          {moment({ currentDate }).format('dddd')}
        </h2>
        {movieListsLoader &&
          movieLists.map((movie, index) => {
            return (
              <div className="row content">
                <div className="col-md-1">
                  <img
                    className="img-fluid"
                    src={
                      getLangSpecificAttribute(
                        movie.MovieContent,
                        lang_id,
                        'artwork',
                      ) || dummy_image
                    }
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = () => {}; // prevents looping
                      currentTarget.src = dummyPosterStatic;
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <h3 className="text-limit v3 fw-bold">
                    {movie.ss_start_show_time}
                  </h3>
                </div>
                <div className="col-md-8">
                  <h3 className="text-limit v3 fw-bold">
                    {getLangSpecificAttribute(
                      movie.MovieContent,
                      lang_id,
                      'mc_title',
                    )}
                  </h3>
                  <div className="middle-content filter-tags my-3">
                    <div>{movie.language_name}</div>
                    <div>{movie.movie_format}</div>
                  </div>
                  <div className="bottom-content">
                    {/* <div>{timeDifference(movie.ss_start_show_time, movie.ss_end_show_time)}
            </div> */}
                    <div>{movie.mrrdr_interval_time} mins</div>
                    {/* <div>Alterfreigabe 12/12</div> */}
                    <div className="seat-occupied">
                      <div>{movie.rating}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    // <div className="movie_listings">
    //   <div onClick={onClickGoBack} className="go_back">
    //     <div className="button_wrap">
    //       <div className="go_back_button"></div>
    //     </div>
    //     <h2 className="big_text">{t("GO BACK")}</h2>
    //   </div>
    //   <div className="all_movies">
    //     {movies.length > 0 ? (
    //       movies.map((m) => <MovieCard m={m} t={t} />)
    //     ) : (
    //       <div className="go_back">
    //         <h2 className="big_text">{t("No Movies Found")}</h2>
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div>
      {display === 'grid' && movies?.length > 0 && (
        <ScrollArrows
          paginationStartIndex={paginationStartIndex}
          setPaginationStartIndex={setPaginationStartIndex}
          paginationInterval={paginationInterval}
          arrayLength={movies?.length}
        />
      )}
      {/* <CSpinner active={showSpinner} /> */}
      <Container fluid>
        <div>
          <div className="movie_listings">
            <div className="row grid-icons my-4">
              <section className="col-6">
                <GoBack to="prev" />
              </section>
              <section className="col-6 text-right d-flex justify-content-end align-items-center">
                {' '}
                <div className="pr-4" onClick={() => setDisplay('grid')}>
                  <img
                    className={
                      'view-icons btn-group pull-right ' +
                      (display === 'grid' ? '' : 'low-opacity')
                    }
                    src={gridView}
                    alt="icon"
                  />
                </div>
                <div className="pr-4" onClick={() => setDisplay('list')}>
                  <img
                    className={
                      'view-icons btn-group pull-right ' +
                      (display === 'list' ? '' : 'low-opacity')
                    }
                    src={listView}
                    alt="icon"
                  />
                </div>
                <div onClick={() => setDisplay('schedules')}>
                  <img
                    className={
                      'view-icons btn-group pull-right ' +
                      (display === 'schedules' ? '' : 'low-opacity')
                    }
                    src={scheduleView}
                    alt="icon"
                  />
                </div>
              </section>
            </div>
            {display == 'grid' ? (
              <Row>
                {movies.length > 0
                  ? movies.map(
                      (m, index) =>
                        index >= paginationStartIndex &&
                        index <= paginationEndIndex && (
                          <MovieCard
                            m={m}
                            t={t}
                            key={m.movie_id}
                            lang_id={lang_id}
                            dummy_image={dummy_image}
                          />
                        ),
                    )
                  : !moviesLoader && (
                      <div className="go_back">
                        <h2 className="big_text">
                          {t('movie_listing.No Movies Found')}
                        </h2>
                      </div>
                    )}
                {moviesLoader && (
                  <div className="text-center w-100">
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner spinner2"
                    >
                      <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                    <br />
                    <h6>{t('movie_listing.Movies Loading')}</h6>
                  </div>
                )}
              </Row>
            ) : null}

            {display === 'schedules' && <Schedules />}
          </div>
        </div>
      </Container>
      {display === 'list' && <ListMovies />}
    </div>
  );
};

export default withNamespaces()(Movies);
