import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import MovieShow from './MovieShow.jsx';
import dummyPoster from '@assets/imgs/trolls.png';
import MovieFooter from './MovieFooter.jsx';
import calendar from '@assets/imgs/svgs/calendar.svg';
import eye from '@assets/imgs/svgs/eye.svg';
import clock from '@assets/imgs/svgs/clock.svg';
import globe from '@assets/imgs/svgs/globe.svg';
import eyeGreen from '@assets/imgs/svgs/eye-green.svg';
import sandClock from '@assets/imgs/svgs/sand-clock.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useParams } from 'react-router';
import moment from 'moment';
import arrowBack from '@assets/imgs/svgs/arrow-back.svg';
import arrowBackActive from '@assets/imgs/svgs/arrow-back-active.svg';
import calendarWhite from '@assets/imgs/svgs/calendar-white.svg';
import Spinner from './Spinner';
import DayPicker from 'react-day-picker';
import MovieHeader from '@components/partials/MovieHeader';
import { getLangSpecificAttribute } from '@helper/Languages';
import MomentLocaleUtils from 'react-day-picker/moment';

import {
  clearSeatSelection,
  privateScreeningReserveSeat,
} from '../../store/seatLayout/seatLayoutActions';
import {
  fetchMovieDetails,
  fetchMovieSchedules,
  // setStage,
  setBookingType,
  // fetchOtherScreenings,
  setFromMoviePage,
} from '../../store/movies/moviesActions';
import { clearFnbState } from '../../store/foodAndBeverages/foodAndBeveragesActions';

const CreateShowDetails = ({ match, t }) => {
  const [peopleSvg, setPeopleSvg] = useState(true);
  const [back, setBack] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dayPickerBool, setDayPickerBool] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState('youtube');
  const [loginSvg, setLoginSvg] = useState(true);
  const movieFormats = useSelector((state) => state.movies.movieFormats);
  const movieLanguages = useSelector((state) => state.movies.movieLanguages);
  const { movie_id, content_lang_id, isPrivate } = useParams();
  const category_id = 1;
  const genre_id = 1;
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    dispatch(fetchMovieDetails({ movie_id, content_lang_id }));
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        call: 1,
        cinema_id: selectedCinemaFromLocal.cinema_id,
        callback: setModalShow,
        onConfirmShowDetailsPopup,
        onChangeFormat,
        onChangeLanguage,
        onChangeCinema,
      }),
    );
    dispatch(clearSeatSelection());
    dispatch(clearFnbState());
    // dispatch(fetchOtherScreenings({ genre_id, movie_id }));
  }, []);

  useEffect(() => {
    if (false) {
      const spinner = document.getElementById('main-spinner');
      if (spinner) {
        spinner.style.display = 'flex';
        setTimeout(() => {
          spinner.style.display = 'none';
        }, 2000);
      }
    }
  }, []);

  const movieDetails = useSelector((state) => state.movies.movie_details);
  const movieDetailsLoading = useSelector(
    (state) => state.movies.movieDetailsLoading,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedCinemaFromLocal = useSelector(
    (state) => state.movies.selectedCinema,
  );

  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const screeningTypesLoading = useSelector(
    (state) => state.movies.screeningTypesLoading,
  );
  const show = useSelector((state) => state.movies.show);
  const otherScreeningsMovies = useSelector(
    (state) => state.movies.otherScreenings,
  );

  const global = useSelector((state) => state.global);

  const { selected_language, cinema } = global;

  const { lang_id, lang_name, iso_2 } = selected_language;

  useEffect(() => languageSpecificMovieFunction(), [lang_name, movieDetails]);
  let getLanguageSpecificMovieDetails = useRef({});
  const languageSpecificMovieFunction = () => {
    switch (lang_name) {
      case 'English':
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === 'English');
        break;
      case 'German':
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === 'German');
        break;
      case 'Italian':
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === 'Italian');
        break;
      case 'French':
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === 'French');
        break;
      case 'Hindi':
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === 'Hindi');
        break;
      default:
        getLanguageSpecificMovieDetails.current = movieDetails;
    }
    if (getLanguageSpecificMovieDetails.current === undefined) {
      getLanguageSpecificMovieDetails.current = movieDetails;
    }
  };
  const [showPrivateScreenPopup, setShowPrivateScreenPopup] = useState(false);
  const [tempPrivateShowDetails, setTempPrivateShowDetails] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const isPrivateShowClicked = useRef(false);
  const onSelectScreeningType = (type, showName) => {
    dispatch(setBookingType(type));
    if (type === 1 || type === 2) {
      dispatch(setFromMoviePage());
      history.push(
        `/seat-layout/${show.md_id}/${show.ss_id}/${show.screen_id}/${category_id}/${show.gogo_ss_id}/${type}/${movie_id}/${content_lang_id}/${genre_id}/${isPrivate}`,
      );
    } else {
      setShowPrivateScreenPopup(true);
      console.log('showName :>> ', showName);
      let tempShow;
      if (showName) {
        tempShow = showName;
      } else {
        tempShow = show;
      }
      setTempPrivateShowDetails(tempShow);
    }
  };
  const { mycinema_ss_d_id } = useSelector(
    (state) => state.movies.myCinemaCreateShow,
  );
  const onConfirmPrivateScreeningPopup = (specialRequest) => {
    console.log('private show reserved before if statement');
    if (isPrivateShowClicked.current === false) {
      console.log('private show reserved');
      isPrivateShowClicked.current = true;
      console.log(tempPrivateShowDetails);
      dispatch(
        privateScreeningReserveSeat({
          screen_id: tempPrivateShowDetails.screen_id,
          history,
          mycinema_ss_d_id,
          category_id: 1,
          cinema_id: tempPrivateShowDetails.cinema_id,
          movie_id,
          content_lang_id,
          genre_id,
          isPrivate,
          specialRequest,
        }),
      );
    }
  };
  useEffect(() => {
    if (!movieDetails) {
      // alert("hi");
      const spinner = document.getElementById('main-spinner');
      if (spinner) {
        spinner.style.display = 'flex';
        setTimeout(() => setCalendarPopup(false), 10);
        setTimeout(() => {
          spinner.style.display = 'none';
        }, 2000);
      }
      // else {
      //   alert("hi2");
      //   spinner.style.display = "none";
      // }
    }
  }, [movieDetails]);

  const movieSchedules = useSelector((state) => state.movies.movieSchedules);
  const movieSchedulesLoading = useSelector(
    (state) => state.movies.movieSchedulesLoading,
  );
  const movieScheduleDetails = useSelector(
    (state) => state.movies.movieScheduleDetails,
  );
  const dateMovieScheduleDetails = useSelector(
    (state) => state.movies.dateMovieScheduleDetails,
  );
  const filterMovieScheduleDetails = useSelector(
    (state) => state.movies.filterMovieScheduleDetails,
  );

  const onChangeCalenderDate = (value) => console.log(value);
  const todaysScreeningsMovies = useSelector(
    (state) => state.homepage.todaysScreenings,
  );

  const [selectedCinema, setSelectedCinema] = useState(cinema);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedLanguage2, setSelectedLanguage2] = useState(null);
  const [selectedFormat2, setSelectedFormat2] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);

  useEffect(() => {
    if (dateMovieScheduleDetails?.dateList?.length > 0) {
      setSelectedDate(dateMovieScheduleDetails.dateList[0].ss_actual_start_date);
    }
  }, [dateMovieScheduleDetails]);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const onChangeDate = (date) => {
    console.log('date :>> ', date);
    // console.log("new Date(date.dateFormat) :>> ", new Date(date.dateFormat));
    onChange(new Date(date.ss_actual_start_date));
    const { ss_actual_start_date } = date;
    // setSelectedLanguage(null);
    // setSelectedFormat(null);
    // setSelectedLanguage2(null);
    // setSelectedFormat2(null);
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        dateSchedule: ss_actual_start_date,
        cinema_id: selectedCinema?.cinema_id
          ? selectedCinema.cinema_id
          : selectedCinemaFromLocal?.cinema_id,
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
        call: 2,
        callback: setModalShow,
        setSelectedLanguage,
        setSelectedFormat,
        setSelectedLanguage2,
        setSelectedFormat2,
        selectedLanguage2,
        selectedFormat2,
      }),
    );
    forceUpdate();
    setSelectedDate(ss_actual_start_date);
  };
  const onChangeCinema = (cinema, fetchSchedule = true) => {
    const { cinema_id } = cinema;
    console.log(cinema);
    if (fetchSchedule) {
      dispatch(
        fetchMovieSchedules({
          movie_id,
          category_id,
          cinema_id,
          dateSchedule: moment(selectedDate).format('YYYY-MM-DD'),
          lang_id: selectedLanguage?.lang_id,
          mf_id: selectedFormat?.mf_id,
        }),
      );
    }
    setSelectedCinema(cinema);
  };
  const onChangeLanguage = (language) => {
    const { lang_id } = language;

    setSelectedLanguage2(language);
    // setModalShow(false);
  };
  const onConfirmShowDetailsPopup = (
    selectedLanguageNew,
    selectedFormatNew,
    date = null,
  ) => {
    if (selectedLanguage2 || selectedLanguageNew)
      setSelectedLanguage(selectedLanguageNew);
    if (selectedFormat2 || selectedFormatNew) setSelectedFormat(selectedFormatNew);

    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        cinema_id: selectedCinema?.cinema_id
          ? selectedCinema.cinema_id
          : selectedCinemaFromLocal?.cinema_id,
        dateSchedule: date
          ? moment(date).format('YYYY-MM-DD')
          : moment(selectedDate).format('YYYY-MM-DD'),
        lang_id: selectedLanguageNew?.lang_id,
        mf_id: selectedFormatNew?.mf_id,
        // dateNull: true,
      }),
    );
    setModalShow(false);
  };

  const onChangeFormat = (format) => {
    const { mf_id } = format;
    setSelectedFormat2(format);
  };
  const stage = 1;
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [calenderDateSelected, setCalenderDateSelected] = useState(false);

  const movieSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      otherScreeningsMovies.length > 6 ? 6 : otherScreeningsMovies.length,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
    ],
  };
  let footerObj = {
    image:
      getLangSpecificAttribute(
        movieDetails?.languageSpecificImages,
        lang_id,
        'artwork',
      ) || dummyPoster,
    movie_title:
      getLangSpecificAttribute(
        movieDetails?.altMovieContent,
        lang_id,
        'mc_title',
      ) || movieDetails?.movie_title,
    lang: selectedLanguage?.lang_name,
    format: selectedFormat?.mf_name,
    genre:
      movieDetails?.genres_with_translations?.[0]?.[iso_2?.toLowerCase()] ||
      movieDetails?.genre,
    rating: movieDetails?.rating,
    cinemaName: selectedCinema?.cinema_name,
    date: selectedDate,
    cine_address: selectedCinema?.cine_address || '',
    cine_location: selectedCinema?.cine_location || '',
  };

  const dateSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 12,
          slidesToScroll: 12,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          arrows: true,
          slidesToShow: 10,
          slidesToScroll: 10,
        },
      },
      {
        breakpoint: 900,
        settings: {
          arrows: true,
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <div className="">
      {/* POPUP START */}
      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        className="show-details-popup"
        centered
        onHide={() => setModalShow(false)}
      >
        <section className="wrapper container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-4 d-none d-md-block">
              <div className="poster-img">
                <img
                  src={
                    getLangSpecificAttribute(
                      movieDetails?.languageSpecificImages,
                      lang_id,
                      'artwork',
                    ) || dummyPoster
                  }
                  alt={t('alt.Poster')}
                  className=""
                />
              </div>
            </div>
            <div className="col-sm-8">
              <h4 className="heading">
                {' '}
                {getLangSpecificAttribute(
                  movieDetails?.altMovieContent,
                  lang_id,
                  'mc_title',
                )}
              </h4>
              <div className="tags">
                {movieDetails?.genres_with_translations
                  ?.filter((genre) => genre[iso_2.toLowerCase()])
                  ?.map(
                    (genre, index) =>
                      genre[iso_2.toLowerCase()] && (
                        <span key={index}>{genre[iso_2.toLowerCase()]}</span>
                      ),
                  )}
              </div>
              <div className="features">
                <div className="features-part">
                  {/* {showDetails.features.map((feature) => (
                    <div>
                      <img src={calendar} alt="calendar" />
                      <span>{feature}</span>
                    </div>
                  ))} */}
                  <div>
                    <img src={calendar} alt={t('alt.calendar')} />
                    <span>
                      {moment(movieDetails?.mrrdr_release_date).format('YYYY')}
                    </span>
                  </div>
                  <div>
                    <img src={eye} alt={t('alt.calendar')} />
                    <span>{movieDetails?.rating}</span>
                  </div>
                  <div>
                    <img src={clock} alt={t('alt.calendar')} />
                    <span>
                      {movieDetails?.mrrdr_runtime} {t('common.mins')}
                    </span>
                  </div>
                  <div>
                    <img src={globe} alt={t('alt.calendar')} />
                    {/* <span>{t("common.DE")}</span> */}
                    <span>{movieDetails?.mf_name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="px-3 w-100">
              <h6 className="sub-heading">
                {t('Choose Language')}
                {/* {JSON.stringify(movieScheduleDetails?.LanguageArray)} */}
              </h6>
              <div className="btn-main-group">
                {movieLanguages?.length > 0 &&
                  movieLanguages?.map((language, index) => (
                    // add class active in button element when selected
                    <button
                      className={`tile ${
                        language.lang_id === selectedLanguage2?.lang_id && 'active'
                      }`}
                      onClick={() => onChangeLanguage(language)}
                      key={index}
                    >
                      {language.lang_name}
                    </button>
                  ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="px-3 w-100">
              <h6 className="sub-heading">{t('Choose Format')}</h6>
              <div className="btn-main-group">
                {!movieSchedulesLoading &&
                  movieFormats?.map((format, index) => (
                    // add class active in button element when selected
                    <button
                      key={index}
                      className={`tile format ${
                        format.mf_id === selectedFormat2?.mf_id && 'active'
                      }`}
                      onClick={() => onChangeFormat(format)}
                    >
                      {format.mf_name}
                    </button>
                  ))}
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="px-3 mt-4 mx-auto">
              {/* add class inactive when it is not selectable */}
              <button
                className={`blue-btn ${
                  selectedLanguage2 && selectedFormat2 ? `` : `inactive`
                }`}
                onClick={() =>
                  onConfirmShowDetailsPopup(selectedLanguage2, selectedFormat2)
                }
              >
                {t('Confirm')}
              </button>
            </div>
          </div>
        </section>
      </Modal>
      {/* POPUP END */}

      <MovieHeader />

      {true && (
        <div className="container-fluid container-xl header-space">
          <p className="mx-auto big-heading text-center pt-5">
            {t('Select Time of your Movie')}
          </p>
          <section className="row justify-content-center for-prev-step">
            {/* {stage !== 1 && ( */}
            {true && (
              <div className="prev-step">
                <button
                  className="blue-btn mx-auto mt-3 mt-xl-4 px-4 py-2"
                  // onClick={() => setStage(stage - 1)}
                  onClick={() => history.go(-1)}
                  onMouseOver={() => setBack(true)}
                  onMouseOut={() => setBack(false)}
                >
                  <img
                    src={back ? arrowBackActive : arrowBack}
                    className="mr3"
                    alt=""
                  />
                  {/* Back */}
                </button>
              </div>
            )}

            <div className="col-sm-9 mx-auto">
              <div className="steps">
                <div
                  className={`step ticket ${
                    true === 1
                      ? 'complete'
                      : false
                      ? 'complete'
                      : false
                      ? 'complete'
                      : ''
                  }`}
                >
                  {/* <img src={ticketRed} /> */}
                </div>
                <div
                  className={`step screen ${
                    true ? 'complete' : false ? 'complete' : ''
                  }`}
                >
                  {/* <img src={ticketRed} /> */}
                </div>
                <div className={`step seat ${true ? 'complete' : ''}`}>
                  {/* <img src={ticketRed} /> */}
                </div>
              </div>
            </div>
            {/* </div> */}
          </section>
        </div>
      )}
      {stage === 1 && (
        <>
          <div className="date-picker-section">
            <div className="container-fluid">
              {dateMovieScheduleDetails?.dateList?.length ? (
                <section className="row date-picker-sect">
                  <div className="col-10 col-md-11 mx-auto col-xl-4 mb-0 mb-md-4 mb-xl-0">
                    <Slider {...dateSlider} className="date-slider-2">
                      {dateMovieScheduleDetails?.dateList?.map(
                        (date, index) =>
                          index < 7 && (
                            <div key={index}>
                              <span
                                className={`date-section ${
                                  date?.isDateAvailable === false ? 'inactive' : ''
                                } ${
                                  date.ss_actual_start_date === selectedDate &&
                                  'selected'
                                }`}
                                onClick={() => onChangeDate(date)}
                              >
                                <p className="month">
                                  {moment(date.ss_actual_start_date)
                                    .locale(iso_2.toLowerCase())
                                    .format('MMM')}
                                </p>
                                <p className="date">{date.day}</p>
                                <p className="day">
                                  {moment(date.ss_actual_start_date)
                                    .locale(iso_2.toLowerCase())
                                    .format('dddd')}
                                </p>
                              </span>
                            </div>
                          ),
                      )}
                    </Slider>
                  </div>
                  {
                    <div className="col-xl-7">
                      <div className="d-flex justify-content-between align-items-center h-100">
                        <div className={`config `}>
                          <span
                            className={`actual-text position-relative ${
                              movieSchedules?.CinemaArray?.length < 2
                                ? 'mx-auto'
                                : ''
                            }`}
                            style={{ zIndex: '999' }}
                          >
                            {calendarPopup && (
                              <span
                                className="new-date-picker"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <DayPicker
                                  selectedDays={value}
                                  disabledDays={[
                                    {
                                      before: new Date(),
                                    },
                                    ...(dateMovieScheduleDetails?.dateList
                                      ?.filter((x) => x.isDateAvailable === false)
                                      .map(
                                        (x) => new Date(x.ss_actual_start_date),
                                      ) || []),
                                  ]}
                                  onDayClick={(date, modifiers) => {
                                    if (modifiers && modifiers.disabled) return;
                                    setCalendarPopup(false);
                                    setCalenderDateSelected(true);
                                    onChange(date);
                                    onChangeDate({
                                      ss_actual_start_date:
                                        moment(date).format('YYYY-MM-DD'),
                                    });
                                  }}
                                  localeUtils={MomentLocaleUtils}
                                  locale={iso_2.toLowerCase()}
                                />
                              </span>
                            )}
                            <img
                              src={calendarWhite}
                              className="md-icon mr-2 mr-md-3"
                              alt=""
                            />
                            <span
                              className="title"
                              onClick={(e) => {
                                calendarPopup
                                  ? setCalendarPopup(false)
                                  : setCalendarPopup(true);
                                e.stopPropagation();
                              }}
                            >
                              {/* calenderDateSelected */}
                              {/* {value} */}
                              {false
                                ? t('Choose Date')
                                : moment(selectedDate)
                                    .locale(iso_2.toLowerCase())
                                    .format('MMM D')}
                              {/*  */}
                              {/* {JSON.stringify(selectedDate)}
                      {JSON.stringify(value)} */}
                            </span>
                          </span>
                        </div>

                        {movieSchedules?.CinemaArray?.length > 1 && (
                          <div className="config">
                            <div className="">
                              <Dropdown className="multiselect">
                                <Dropdown.Toggle
                                  className="dropdownClassic"
                                  id="dropdown-basic"
                                >
                                  <span className="title">
                                    {selectedCinema
                                      ? selectedCinema.cinema_name
                                      : t('home_page.select-cinema')}
                                  </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  align="right"
                                  className="dropdownItems"
                                >
                                  {movieSchedules?.CinemaArray?.map(
                                    (cinema, index) => (
                                      <Dropdown.Item
                                        key={index}
                                        className={
                                          selectedCinema?.cinema_id ===
                                            cinema?.cinema_id && 'selected'
                                        }
                                        onClick={() => onChangeCinema(cinema)}
                                      >
                                        {cinema?.cinema_name}
                                      </Dropdown.Item>
                                    ),
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        )}

                        <div className={`config d-none d-md-flex `}>
                          <span
                            className={`title cursor-pointer ${
                              movieSchedules?.CinemaArray?.length < 2
                                ? 'mx-auto'
                                : ''
                            }`}
                            onClick={() => setModalShow(true)}
                          >
                            {selectedLanguage
                              ? selectedLanguage?.lang_name === 'All'
                                ? t('common.Language')
                                : selectedLanguage?.lang_name
                              : t('common.Language')}
                            &nbsp; &amp; &nbsp;
                            {selectedFormat
                              ? selectedLanguage?.lang_name === 'All'
                                ? t('common.Format')
                                : selectedFormat.mf_name
                              : t('common.Format')}
                          </span>
                        </div>

                        {/* fixed settings icon for mobile starts */}
                        {/* <div
                        className="settings-icon"
                        onClick={() => setModalShow(true)}
                      >
                        {selectedLanguage
                          ? selectedLanguage?.lang_name === "All"
                            ? t("common.Language")
                            : selectedLanguage?.lang_name
                          : t("common.Language")}
                        &nbsp; &amp; &nbsp;
                        {selectedFormat
                          ? selectedLanguage?.lang_name === "All"
                            ? t("common.Format")
                            : selectedFormat.mf_name
                          : t("common.Format")}
                      </div> */}
                      </div>
                    </div>
                  }
                </section>
              ) : (
                <p className="my-2 no-result">{t('No show dates found')}</p>
              )}
            </div>
          </div>
          {/* Markings */}
          <div className={`container-fluid ${true ? 'movie-footer-space' : ''}`}>
            {
              // category_id == 2 &&
              false && movieSchedules?.MovieListArray.length > 0 && (
                <div className="row show-status">
                  <div className="px-3 mx-auto">
                    <ul className="list-inline mb-0 d-flex align-items-center">
                      <li className="list-inline-item">
                        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
                          <img
                            src={eyeGreen}
                            alt="green eye"
                            className="mr-3 mb-2 mb-sm-0"
                          />
                          <p>{t('Confirmed Show | minimum seats booked')}</p>
                        </div>
                      </li>
                      <li className="list-inline-item">
                        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
                          <img
                            src={sandClock}
                            alt="sand clock"
                            className="mr-3 mb-2 mb-sm-0"
                          />
                          <p>{t('Less than minimum seats booked')}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )
            }
            {/* Shows */}
            {movieSchedulesLoading ? (
              <div className="row">
                <div className="col-12 text-center">
                  <Spinner />
                </div>
              </div>
            ) : movieSchedules?.MovieListArray.length === 0 ? (
              <div className="row">
                <div className="col-12 text-center">
                  {/* <h4>{t("No shows found")}</h4> */}
                  <p className="no-result">
                    {t('sorry-on-this-date-private-ondemand-not-available')}
                  </p>
                </div>
              </div>
            ) : movieSchedules &&
              movieSchedules?.MovieListArray[0] &&
              movieSchedules?.MovieListArray[0] &&
              movieSchedules?.MovieListArray[0]?.CinemaList[0] &&
              movieSchedules?.MovieListArray[0]?.CinemaList[0]?.format_list[0] &&
              movieSchedules?.MovieListArray[0]?.CinemaList[0]?.format_list[0]
                ?.schedule_list &&
              movieSchedules?.MovieListArray[0]?.CinemaList[0]?.format_list[0]
                ?.schedule_list?.length === 0 ? (
              <div className="row">
                <div className="col-12 text-center">
                  {/* <h4>{t("No shows found")}</h4> */}
                  <p className="no-result">
                    {t('sorry-on-this-date-private-ondemand-not-available')}
                  </p>
                </div>
              </div>
            ) : (
              movieSchedules &&
              movieSchedules?.MovieListArray &&
              movieSchedules?.MovieListArray[0]?.CinemaList &&
              movieSchedules?.MovieListArray[0]?.CinemaList?.map(
                (cinema, index) => (
                  <MovieShow
                    key={index}
                    theatre={cinema.cinema_name}
                    theatreAddress={`${cinema?.cine_address}`}
                    languages={cinema.format_list}
                    shows={cinema.format_list}
                    privateCallback={(type, item) => onSelectScreeningType(3, item)}
                    isPrivate={isPrivate}
                    movie_id={movie_id}
                    content_lang_id={content_lang_id}
                    category_id={category_id}
                    genre_id={genre_id}
                    cinemaIndex={index}
                    cinema={cinema}
                    selectedCinema={
                      selectedCinema ? selectedCinema : selectedCinemaFromLocal
                    }
                  />
                ),
              )
            )}
          </div>

          {<MovieFooter {...footerObj} />}
        </>
      )}
    </div>
  );
};

export default withNamespaces()(CreateShowDetails);
