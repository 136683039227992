import React, { Component, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import moment from 'moment';
import { PaymentService } from '@apiService/PaymentService';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import { SOCKET_URL } from '@config';
import clock from '@assets/imgs/svgs/clock.svg';
import { bookSeats, bookFnb } from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
import GoBack from '@components/partials/GoBack';

// import { globalConfigContext } from '@context/GlobalConfigContext';
var wsUrl = SOCKET_URL;
var socket = new WebSocket(wsUrl);

const CheckoutTwo = (props) => {
  return <></>;
};

export default withNamespaces()(CheckoutTwo);
