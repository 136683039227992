import {
  FETCH_NOW_PLAYING_MOVIES,
  FETCH_NOW_PLAYING_ERROR,
  FETCH_BANNERS,
  FETCH_BANNERS_ERROR,
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_SHOW_TIMES,
  FETCH_SHOW_TIMES_ERROR,
  FETCH_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_DETAILS_LOADER,
  SET_SHOW_TIMES_LOADER,
  CLEAR_MOVIE_DETAILS_ERROR,
  SET_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_FORMATS,
  SET_MOVIES_LOADER,
  SET_SHOW_DATE_LOADER,
  FETCH_DATE_ALL_SHOWS,
  FETCH_DATE_ALL_SHOWS_LOADING,
  SET_SELECTED_SESSION,
  FETCH_ALL_MOVIES_SCHEDULES,
  FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
  FETCH_ALL_MOVIES_SCHEDULES_ERROR,
  SET_STAGE,
  FETCH_SCHEDULE_DETAILS,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
  FETCH_SCHEDULE_DETAILS_ERROR,
  SET_SHOW,
  FETCH_MOVIE_DETAILS_SUCCESS,
  FETCH_MOVIE_SCHEDULES_SUCCESS,
  FETCH_MOVIE_SCHEDULES_SUCCESS2,
  FETCH_MOVIE_SCHEDULES_SUCCESS3,
  SET_MOVIE_LANGUAGES,
  FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
  FETCH_MOVIE_SCHEDULES_ERROR,
  FETCH_MOVIE_SCHEDULES,
  SET_BOOKING_TYPE,
  SET_FROM_MOVIE_PAGE,
  CREATE_SHOW_SUCCESS,
  CREATE_SHOW_ERROR,
  FETCH_SCREENING_TYPES,
  FETCH_SCREENING_TYPES_SUCCESS,
  SET_PRIVATE_SCREEN_AMOUNT,
  FETCH_SCREENING_TYPES_ERROR,
  SET_TIMEOUT_ERROR,
  FETCH_RESERVATION_DETAILS,
  FETCH_RESERVATION_DETAILS_SUCCESS,
  FETCH_RESERVATION_DETAILS_ERROR,
  CLEAR_BOOKING_TYPE,
} from './moviesTypes';
import MovieService from '@apiService/MovieService';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';

export const setStage = (payload) => async (dispatch, getState) => {
  if (!payload.show) {
    dispatch({
      type: SET_STAGE,
      payload,
    });
  } else {
    const { mycinema_ss_d_id } = getState().movies.myCinemaCreateShow;
    console.log(
      '🚀 ~ file: movieActions.js ~ line 382 ~ setStage ~ mycinema_ss_d_id',
      mycinema_ss_d_id,
    );
    console.log('payload :>> ', payload);
    // dispatch(fetchScreeningTypes({ mycinema_ss_d_id, payload }));
    if (payload.direct !== true) {
      payload.history.push(`/screening-type/${mycinema_ss_d_id}`);
    }
  }
};

const fetchNowPlayingSuccess = (data) => {
  return {
    type: FETCH_NOW_PLAYING_MOVIES,
    payload: data,
  };
};

const fetchNowPlayingError = (err) => {
  return {
    type: FETCH_NOW_PLAYING_ERROR,
    payload: err,
  };
};

const fetchMovieDetailsSuccess = (data) => {
  return {
    type: FETCH_MOVIE_DETAILS,
    payload: data,
  };
};

const fetchMovieDetailsError = (error) => {
  return {
    type: FETCH_MOVIE_DETAILS_ERROR,
    payload: error,
  };
};

const fetchShowTimeSuccess = (data) => {
  return {
    type: FETCH_SHOW_TIMES,
    payload: data,
  };
};

const fetchShowTimeError = (error) => {
  return {
    type: FETCH_SHOW_TIMES_ERROR,
    payload: error,
  };
};

const fetchCurrentMovieShowDates = (payload) => ({
  type: SET_CURRENT_MOVIE_SHOW_DATES,
  payload,
});

const setShowTimesLoader = (payload) => ({
  type: SET_SHOW_TIMES_LOADER,
  payload,
});

const setCurrentShowLoader = (payload) => ({
  type: SET_SHOW_DATE_LOADER,
  payload,
});

const setMoviesLoader = (payload) => ({
  type: SET_MOVIES_LOADER,
  payload,
});

const setMovieDetailsLoader = (payload) => ({
  type: SET_MOVIE_DETAILS_LOADER,
  payload,
});

export const clearMovieDetailsError = (payload) => ({
  type: CLEAR_MOVIE_DETAILS_ERROR,
});

export const setAllShowsLoading = (payload) => ({
  type: FETCH_DATE_ALL_SHOWS_LOADING,
  payload,
});

export const setSelectedSession = (payload) => ({
  type: SET_SELECTED_SESSION,
  payload,
});

export const fetchNowPlayingMovies = ({ cinema_id }) => {
  return async function (dispatch, getState) {
    const {
      global: {
        selected_language: { lang_id },
      },
    } = getState();
    dispatch(setMoviesLoader(true));
    dispatch({ type: 'RESET_NOW_PLAYING_MOVIES' });

    try {
      const payload = {
        aggregator_cinema_id: cinema_id,
        isScheduled: true,
        lang_id,
      };
      // date: moment().format("DD-MM-YYYY"),
      const { data } = await MovieService.GetAllShows(payload);
      if (data.status && data.Records.length > 0) {
        const key = 'movie_id';
        let uniqueMovies = [
          ...new Map(data.Records.map((item) => [item[key], item])).values(),
        ];
        uniqueMovies.sort((a, b) =>
          a.movie_title !== b.movie_title
            ? a.movie_title < b.movie_title
              ? -1
              : 1
            : 0,
        );
        dispatch(fetchNowPlayingSuccess(uniqueMovies));
      }
    } catch (err) {
      dispatch(fetchNowPlayingError(err.message));
    } finally {
      dispatch(setMoviesLoader(false));
    }
  };
};

export const fetchMovieDetails = (payload) => {
  return function (dispatch) {
    dispatch(setMovieDetailsLoader(true));
    MovieService.GetMovieDetails(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.Records.length > 0) {
          dispatch(fetchMovieDetailsSuccess(data.Records[0]));
        }
      })
      .catch((err) => {
        dispatch(fetchMovieDetailsError(err.message));
      });
  };
};

export const fetchShowTimes =
  ({ date, cinema_id, movieFormats, md_id }) =>
  async (dispatch) => {
    try {
      let newMovieTimesArray = [];
      dispatch(setShowTimesLoader(true));
      // for (let i = 0; i < movieFormats.length; i++) {
      //   const { data } = await MovieService.GetShowTimes({
      //     date,
      //     md_id: movieFormats[i].md_id,
      //     cinema_id,
      //   });
      //   if (data && data.status) {
      //     dispatch(setShowTimesLoader(false));
      //   }
      //   if (data?.status && data?.Records?.length > 0) {
      //     newMovieTimesArray = [...newMovieTimesArray, ...data?.Records];
      //   }
      // }
      const { data } = await MovieService.GetShowTimes({
        date,
        cinema_id,
        md_id,
      });
      if (data && data.status) {
        dispatch(setShowTimesLoader(false));
      }
      if (data?.status && data?.Records?.length > 0) {
        newMovieTimesArray = data?.Records;
      }
      dispatch(fetchShowTimeSuccess(newMovieTimesArray));
    } catch (err) {
      dispatch(fetchShowTimeError(err.message));
    }
  };

export const fetchMovieDates = (payload) => {
  return function (dispatch) {
    dispatch({ type: FETCH_CURRENT_MOVIE_SHOW_DATES });
    dispatch(setCurrentShowLoader(true));

    // if (!payload.movie_id || !payload.cinema_id) {
    //   return;
    // }
    MovieService.GetMovieDates(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.Records.length > 0) {
          const dates = [
            ...new Set(
              data.Records.map(
                (date) => (date = moment(date.ss_start_date).format('DD-MM-YYYY')),
              ),
            ),
          ];

          dispatch(fetchCurrentMovieShowDates(dates));
        }
      })
      .catch((err) => {
        console.log(err.response ? err.response : err);
      });
  };
};

export const fetchMovieFormats =
  ({ movie_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: SET_MOVIE_FORMATS, payload: [] });
      const { data } = await MovieService.GetMovieFilters({ movie_id });
      if (data?.status && data?.Records?.length > 0) {
        dispatch({ type: SET_MOVIE_FORMATS, payload: data.Records });
      }
    } catch (error) {
      console.error(error);
    }
  };

export const fetchDateAllShows =
  ({ selectedDate, cinema_id, rating_id, format_id }) =>
  async (dispatch) => {
    try {
      let newMovieTimesArray = [];
      dispatch(setAllShowsLoading(true));
      const { data } = await MovieService.GetAppAllShows({
        cinema_id,
        rating_id,
        format_id,
        is_kiosk: true,
      });
      console.log(data);
      if (data && data.status) {
      }
      if (data?.status && data?.Records?.length > 0) {
        // newMovieTimesArray = [...newMovieTimesArray, ...data?.Records];
        newMovieTimesArray = _.sortBy(
          _.toPairs(
            _.groupBy(data.Records, (x) =>
              moment(x.ss_actual_start_date).format('YYYY-MM-DD'),
            ),
          ),
          [(x) => x[0]],
        ).map((x) => ({
          date: x[0],
          shows: x[1],
        }));

        console.log(
          '🚀 ~ file: moviesActions.js ~ line 249 ~ newMovieTimesArray=_.sortBy ~ newMovieTimesArray',
          newMovieTimesArray,
        );
      }
      dispatch({ type: FETCH_DATE_ALL_SHOWS, payload: newMovieTimesArray });
    } catch (err) {
      dispatch(fetchShowTimeError(err.message));
    } finally {
      dispatch(setAllShowsLoading(false));
    }
  };

export const fetchAllMoviesSchedules = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_MOVIES_SCHEDULES });

    const { data } = await MovieService.GetAllMoviesAndSchedules(payload);
    if (data.status) {
      dispatch({
        type: FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
        payload: {
          movieSchedules: data.Records,
          datesArray: data.Dates,
        },
      });
    } else {
      dispatch({
        type: FETCH_ALL_MOVIES_SCHEDULES_ERROR,
        payload: data.message,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCH_ALL_MOVIES_SCHEDULES_ERROR,
      payload: err.message,
    });
  }
};

export const fetchScheduleDetails =
  ({ ss_id, category_id, mycinema_ss_d_id, booking_type_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SCHEDULE_DETAILS });
      const service =
        category_id === 1
          ? MovieService.GetScheduleDetails
          : MovieService.GetCinemaScheduleDetails;
      const { data } = await service({
        ss_id,
        mycinema_ss_d_id,
        booking_type_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: { ...data.Records[0], ss_id },
        });
        dispatch({
          type: FETCH_MOVIE_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: SET_SHOW,
          payload: { ...data.Records[0], category_id },
        });
      } else {
        dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: error });
    }
  };

export const fetchMovieSchedules =
  ({
    movie_id,
    dateSchedule,
    cinema_id,
    lang_id,
    mf_id,
    call,
    callback,
    onConfirmShowDetailsPopup,
    onChangeFormat,
    onChangeLanguage,
    dateNull,
    setSelectedLanguage,
    setSelectedFormat,
    setSelectedLanguage2,
    setSelectedFormat2,
    onChangeCinema,
    selectedLanguage2,
    selectedFormat2,
  }) =>
  async (dispatch) => {
    try {
      console.log(`call`, call);
      console.log(`lang_id`, lang_id);
      console.log(`mf_id`, mf_id);
      dispatch({ type: FETCH_MOVIE_SCHEDULES });
      const { data } = await MovieService.GetMovieSchedules({
        movie_id,
        queries: {
          fromDate: dateNull ? null : dateSchedule,
          cinema_id,
          lang_id,
          mf_id,
        },
      });
      if (data.status) {
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS,
          payload: data,
        });
        if (call === 1) {
          dispatch({ type: FETCH_MOVIE_SCHEDULES_SUCCESS2, payload: data });
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
            payload: data,
          });

          dispatch({ type: SET_MOVIE_FORMATS, payload: data.FormatArray });
          dispatch({ type: SET_MOVIE_LANGUAGES, payload: data.LanguageArray });

          if (
            data.MovieListArray.length > 0 &&
            (data.LanguageArray.length > 1 || data.FormatArray.length > 1)
          ) {
            // callback(true);

            if (data.LanguageArray.length === 1) {
              // setSelectedLanguage(data.LanguageArray[0]);
              // onChangeLanguage(data.LanguageArray[0]);
            }

            if (data.FormatArray.length === 1) {
              // setSelectedFormat(data.FormatArray[0]);
              // onChangeFormat(data.FormatArray[0]);
            }
          } else if (data.MovieListArray.length > 0) {
            console.log(
              'inital date obj ======> 1',
              data.dateList[0].ss_actual_start_date,
            );
            onChangeFormat(data.FormatArray[0]);
            onChangeLanguage(data.LanguageArray[0]);
            onConfirmShowDetailsPopup(
              data.LanguageArray[0],
              data.FormatArray[0],
              data.dateList[0].ss_actual_start_date,
            );
          } else {
            console.log(
              'inital date obj ======> 2',
              data.dateList[0].ss_actual_start_date,
            );
            onConfirmShowDetailsPopup(
              null,
              null,
              data.dateList[0].ss_actual_start_date,
            );
          }
          if (data?.CinemaArray.length === 1) {
            onChangeCinema(data?.CinemaArray[0], false);
          }
        } else if (call === 2) {
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
            payload: data,
          });
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
            payload: data,
          });

          if (
            data.MovieListArray.length > 0 &&
            (data.LanguageArray.length > 1 || data.FormatArray.length > 1)
          ) {
            if (!lang_id || !mf_id) {
              // callback(true);
            }
            // if (
            //   data.LanguageArray.find(
            //     (x) => x.lang_id === selectedLanguage2?.lang_id
            //   ) === undefined ||
            //   data.FormatArray.find((x) => x.mf_id === selectedFormat2?.mf_id) ===
            //     undefined
            // ) {
            //   callback(true);
            // } else {
            //   if (selectedLanguage2) {
            //     setSelectedLanguage(selectedLanguage2);
            //   }

            //   if (selectedFormat2) {
            //     setSelectedFormat(selectedFormat2);
            //   }
            // }

            if (!lang_id && data.LanguageArray.length === 1) {
              // setSelectedLanguage2(data.LanguageArray[0]);
            }

            if (!mf_id && data.FormatArray.length === 1) {
              // setSelectedFormat2(data.FormatArray[0]);
            }
          } else if (data.MovieListArray.length > 0) {
            if (!lang_id && data.LanguageArray.length > 0) {
              // setSelectedLanguage(data.LanguageArray[0]);
              // setSelectedLanguage2(data.LanguageArray[0]);
            }

            if (!mf_id && data.FormatArray.length > 0) {
              // setSelectedFormat(data.FormatArray[0]);
              // setSelectedFormat2(data.FormatArray[0]);
            }
          }
        }
      } else {
        dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: error });
    }
  };

export const setBookingType = (payload) => async (dispatch) =>
  dispatch({
    type: SET_BOOKING_TYPE,
    payload,
  });

export const setFromMoviePage = () => ({
  type: SET_FROM_MOVIE_PAGE,
});

export const createShow =
  ({ mrrdr_runtime, start_time, schedule_date, cinema_ss_id }, history, direct) =>
  async (dispatch) => {
    try {
      // dispatch({ type: CREATE_SHOW });
      const payload = {
        mrrdr_runtime,
        start_time,
        schedule_date,
        cinema_ss_id,
        guest_user_id: Math.floor(1000 + Math.random() * 9000),
        //guest_user_id: localStorage.getItem(`guest_user_id`),
      };
      const { data } = await MovieService.CreateShow(payload);
      console.log('🚀 ~ file: movieActions.js ~ line 1822 ~ )=> ~ data', data);
      if (data.status) {
        dispatch({
          type: CREATE_SHOW_SUCCESS,
          payload: data,
        });
        // toast.success(data.message);
        dispatch(
          setStage({
            stage: 2,
            show: { mrrdr_runtime, start_time, schedule_date, cinema_ss_id },
            history,
            direct,
          }),
        );
      } else {
        console.log('its coming here because of error in create show');
        dispatch({ type: CREATE_SHOW_ERROR, payload: data.message });
        toast.error(data.message);
      }
    } catch (error) {
      dispatch({ type: CREATE_SHOW_ERROR, payload: error });
    }
  };

export const fetchScreeningTypes =
  ({ mycinema_ss_d_id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SCREENING_TYPES });
      const { data } = await MovieService.GetScreeningTypes({
        mycinema_ss_d_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_SCREENING_TYPES_SUCCESS,
          payload: data.Records,
        });

        dispatch({
          type: SET_STAGE,
          payload,
        });
        const privateType = data.Records.find((type) => type.booking_type_id === 3);
        dispatch({
          type: SET_PRIVATE_SCREEN_AMOUNT,
          payload: {
            amount: privateType.amount,
            net_amount: privateType.net_amount,
          },
        });
      } else {
        dispatch({
          type: FETCH_SCREENING_TYPES_ERROR,
          payload: { message: data.message },
        });
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: data.message, show: true },
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_SCREENING_TYPES_ERROR, payload: error });
    }
  };

export const fetchReservationDetails =
  ({ reservation_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_RESERVATION_DETAILS });
      const { data } = await MovieService.GetReservationDetails({
        reservation_id,
      });
      if (data && data.status) {
        dispatch({
          type: FETCH_RESERVATION_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_RESERVATION_DETAILS_ERROR, error });
    }
  };

export const clearMovieBookingType = () => async (dispatch) => {
  dispatch({ type: CLEAR_BOOKING_TYPE });
};
