import Api from './Api';
import { createQueryString } from './ApiHelper';

export default {
  GetGlobalSettings() {
    return Api().get(`/api/pos/getOrgDetails`);
  },
  GetGlobalOptions() {
    return Api().get(`/global_options`);
  },
  GetCinemas(payload) {
    return Api().post(`/api/external/cinemas`, payload);
  },
  GetCurrency({ curr_id }) {
    return Api().get(`/api/external/currency/${curr_id}`);
  },
  GetSignageScreen(payload) {
    return Api().get(`/api/external/signage/${payload.cinema_id}`);
  },
  GetCountries() {
    return Api().get(`/api/external/countries`);
  },
  GetTranslations() {
    return Api().get(`/api/external/translations?module=kiosk`);
  },
  checkCinemaManagerAuth(payload) {
    return Api().post(`/api/external/checkCinemaManagerAuth`, payload);
  },
  fetchCinemaSettings(payload) {
    return Api().get(`/api/external/cinemasetting/${payload}`);
  },
};
