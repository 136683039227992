import {
  FETCH_TODAYS_SCREENINGS,
  FETCH_TODAYS_SCREENINGS_SUCCESS,
  FETCH_TODAYS_SCREENINGS_ERROR,
  FETCH_CREATE_OWN_SHOW,
  FETCH_CREATE_OWN_SHOW_SUCCESS,
  FETCH_CREATE_OWN_SHOW_ERROR,
  FETCH_COMING_SOON,
  FETCH_COMING_SOON_SUCCESS,
  FETCH_COMING_SOON_ERROR,
  FETCH_FORMAT_LIST,
  FETCH_FORMAT_LIST_SUCCESS,
  FETCH_FORMAT_LIST_ERROR,
  FETCH_LANGUAGE_LIST,
  FETCH_LANGUAGE_LIST_SUCCESS,
  FETCH_LANGUAGE_LIST_ERROR,
  FETCH_FESTIVAL_LIST,
  FETCH_FESTIVAL_LIST_SUCCESS,
  FETCH_FESTIVAL_LIST_ERROR,
  FETCH_BANNERS,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNERS_ERROR,
  SEARCH_MOVIE_SUCCESS,
  SEARCH_MOVIE_ERROR,
  SEARCH_MOVIE,
  QUICK_BOOK,
  QUICK_BOOK_MOVIE_SUCCESS,
  QUICK_BOOK_MOVIE_ERROR,
  QUICK_BOOK_MOVIE,
  QUICK_BOOK_LANG_SUCCESS,
  QUICK_BOOK_FORMAT_SUCCESS,
  QUICK_BOOK_CINEMA_SUCCESS,
  QUICK_BOOK_DATE_SUCCESS,
  QUICK_BOOK_TIME_SUCCESS,
  QUICK_BOOK_ERROR,
  FETCH_FILTERS,
  FETCH_FILTERS_SUCCESS,
  FETCH_FILTERS_ERROR,
  SET_FILTER,
  SET_QB_STAGE,
  FETCH_MINI_FESTIVALS,
  FETCH_MINI_FESTIVALS_SUCCESS,
  FETCH_MINI_FESTIVALS_ERROR,
  SET_SELECTED_MINI_FESTIVAL,
} from './homepageTypes';
let initialState = {
  todaysScreenings: null,
  todaysScreeningsLoading: false,
  todaysScreeningsLastPage: null,
  todaysScreeningsError: null,
  createOwnShow: null,
  createOwnShowLastPage: null,
  createOwnShowLoading: false,
  createOwnShowError: null,
  comingSoon: null,
  comingSoonLoading: false,
  comingSoonLastPage: null,
  comingSoonError: null,
  formatList: [],
  formatListLoading: false,
  formatListError: null,
  languageList: [],
  languageListLoading: false,
  languageListError: null,
  festivalList: [],
  festivalListLoading: false,
  festivalListError: null,
  banners: [],
  bannersLoading: false,
  bannersError: null,
  searchMovies: [],
  searchMoviesLoading: false,
  searchMoviesError: null,
  quickBookMovies: [],
  quickBookLanguages: [],
  quickBookFormats: [],
  quickBookCinemas: [],
  quickBookDates: [],
  quickBookTimes: [],
  filters: [],
  qbStage: 0,
  miniFestivals: null,
  miniFestivalsLoading: false,
  miniFestivalsError: null,
  selectedMiniFestival: null,
};
const homepageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_TODAYS_SCREENINGS:
      return {
        ...state,
        todaysScreenings: null,
        todaysScreeningsLastPage: null,
        todaysScreeningsLoading: true,
      };
    case FETCH_TODAYS_SCREENINGS_SUCCESS:
      console.log('payload manav :>> ', payload);
      let lastPage = payload?.pagination?.lastPage
        ? payload?.pagination?.lastPage
        : state.todaysScreeningsLastPage;
      return {
        ...state,
        todaysScreenings:
          state.todaysScreenings === null
            ? payload
            : {
                ...state.todaysScreenings,
                data: [...state.todaysScreenings.data, ...payload.data],
                pagination: {
                  ...state.todaysScreenings.pagination,
                  ...payload.pagination,
                },
              },
        todaysScreeningsLoading: false,
        todaysScreeningsLastPage: lastPage,
      };
    case FETCH_TODAYS_SCREENINGS_ERROR:
      return {
        ...state,
        todaysScreenings: null,
        todaysScreeningsError: payload,
        todaysScreeningsLastPage: null,
        todaysScreeningsLoading: false,
      };

    case FETCH_CREATE_OWN_SHOW:
      return {
        ...state,
        createOwnShow: null,
        createOwnShowLoading: true,
        createOwnShowLastPage: null,
      };
    case FETCH_CREATE_OWN_SHOW_SUCCESS: {
      let lastPage = payload?.pagination?.lastPage
        ? payload?.pagination?.lastPage
        : state.createOwnShowLastPage;
      return {
        ...state,
        createOwnShow:
          state.createOwnShow === null
            ? payload
            : {
                ...state.createOwnShow,
                data: [...state.createOwnShow.data, ...payload.data],
                pagination: {
                  ...state.createOwnShow.pagination,
                  ...payload.pagination,
                },
              },
        createOwnShowLoading: false,
        createOwnShowLastPage: lastPage,
      };
    }

    case FETCH_CREATE_OWN_SHOW_ERROR:
      return {
        ...state,
        createOwnShow: null,
        createOwnShowLoading: false,
        createOwnShowError: payload,
        createOwnShowLastPage: null,
      };
    case FETCH_COMING_SOON:
      return {
        ...state,
        comingSoon: null,
        comingSoonLoading: true,
        comingSoonLastPage: null,
      };
    case FETCH_COMING_SOON_SUCCESS: {
      let lastPage = payload?.pagination?.lastPage
        ? payload?.pagination?.lastPage
        : state.comingSoonLastPage;
      return {
        ...state,
        comingSoon:
          state.comingSoon === null
            ? payload
            : {
                ...state.comingSoon,
                data: [...state.comingSoon.data, ...payload.data],
                pagination: {
                  ...state.comingSoon.pagination,
                  ...payload.pagination,
                },
              },
        comingSoonLoading: false,
        comingSoonLastPage: lastPage,
      };
    }
    case FETCH_COMING_SOON_ERROR:
      return {
        ...state,
        comingSoon: null,
        comingSoonError: payload,
        comingSoonLastPage: null,
      };
    case FETCH_FORMAT_LIST:
      return {
        ...state,
        formatList: [],
        formatListLoading: true,
      };
    case FETCH_FORMAT_LIST_SUCCESS:
      return {
        ...state,
        formatList: payload,
        formatListLoading: false,
      };
    case FETCH_FORMAT_LIST_ERROR:
      return { ...state, formatList: [], formatListError: payload };
    case FETCH_LANGUAGE_LIST:
      return {
        ...state,
        languageList: [],
        languageListLoading: true,
      };
    case FETCH_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        languageList: payload,
        languageListLoading: false,
      };
    case FETCH_LANGUAGE_LIST_ERROR:
      return { ...state, languageList: [], languageListError: payload };
    case FETCH_FESTIVAL_LIST:
      return {
        ...state,
        festivalList: [],
        festivalListLoading: true,
      };
    case FETCH_FESTIVAL_LIST_SUCCESS:
      return {
        ...state,
        festivalList: payload,
        festivalListLoading: false,
      };
    case FETCH_FESTIVAL_LIST_ERROR:
      return { ...state, festivalList: [], festivalListError: payload };
    case FETCH_BANNERS:
      return {
        ...state,
        banners: [],
        bannersLoading: true,
      };
    case FETCH_BANNERS_SUCCESS:
      return {
        ...state,
        banners: payload,
        bannersLoading: false,
      };
    case FETCH_BANNERS_ERROR:
      return { ...state, banners: [], bannersError: payload };
    case SEARCH_MOVIE:
      return {
        ...state,
        searchMovies: null,
        searchMoviesLoading: true,
      };

    case SEARCH_MOVIE_SUCCESS:
      return {
        ...state,
        searchMovies: payload,
        searchMoviesLoading: false,
      };
    case SEARCH_MOVIE_ERROR:
      return { ...state, searchMovies: [], searchMoviesError: payload };
    case QUICK_BOOK_MOVIE:
      return { ...state, quickBookMovies: [] };
    case QUICK_BOOK_MOVIE_ERROR:
      return { ...state, quickBookMovies: [] };
    case QUICK_BOOK_MOVIE_SUCCESS:
      return {
        ...state,
        quickBookMovies: payload,
      };
    case QUICK_BOOK:
      return {
        ...state,
        quickBookMovies: [],
        quickBookLanguages: [],
        quickBookFormats: [],
        quickBookCinemas: [],
        quickBookDates: [],
        quickBookTimes: [],
      };
    case QUICK_BOOK_ERROR:
      return {
        ...state,
        quickBookMovies: [],
        quickBookLanguages: [],
        quickBookFormats: [],
        quickBookCinemas: [],
        quickBookDates: [],
        quickBookTimes: [],
      };

    case QUICK_BOOK_LANG_SUCCESS:
      return {
        ...state,
        quickBookLanguages: payload,
      };
    case QUICK_BOOK_FORMAT_SUCCESS:
      return {
        ...state,
        quickBookFormats: payload,
      };
    case QUICK_BOOK_CINEMA_SUCCESS:
      return {
        ...state,
        quickBookCinemas: payload,
      };
    case QUICK_BOOK_DATE_SUCCESS:
      return {
        ...state,
        quickBookDates: payload,
      };
    case QUICK_BOOK_TIME_SUCCESS:
      return {
        ...state,
        quickBookTimes: payload,
      };
    case SET_QB_STAGE:
      return {
        ...state,
        qbStage: payload,
      };
    case SET_FILTER:
      let newFilters = state.filters;
      newFilters[payload.index].typeArray.map((x) => (x.selected = false));
      newFilters[payload.index].typeArray[payload.typeIndex].selected = true;
      return {
        ...state,
        filters: newFilters,
      };
    case FETCH_FILTERS:
      return {
        ...state,
        filters: [],
      };
    case FETCH_FILTERS_ERROR:
      return {
        ...state,
        filters: [],
      };
    case FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        filters: payload,
      };
    case FETCH_MINI_FESTIVALS:
      return {
        ...state,
        miniFestivalsLoading: true,
      };
    case FETCH_MINI_FESTIVALS_SUCCESS:
      // console.log("payload ajit", payload);
      const { data, index } = payload;
      let festival;

      console.log('state.miniFestivals ====>', state.miniFestivals);

      if (state.miniFestivals === null || index === null) {
        festival = data;
        // if (state.selectedMiniFestival!==null) {
        //   const selectedFestivalIndex = state.miniFestivals.data.findIndex(
        //     (festival) =>
        //       festival.category_id === state.selectedMiniFestival.category_id &&
        //       festival.category_link_image ===
        //         state.selectedMiniFestival.category_link_image &&
        //       festival.category_link_movie_id ===
        //         state.selectedMiniFestival.category_link_movie_id &&
        //       festival.movie_id === state.selectedMiniFestival.movie_id &&
        //       festival.from_date === state.selectedMiniFestival.from_date &&
        //       festival.created_at === state.selectedMiniFestival.created_at
        //   );
        //   console.log(
        //     "🚀 ~ file: movieReducer.js ~ line 268 ~ movieReducer ~ selectedFestivalIndex",
        //     selectedFestivalIndex
        //   );
        // }
      } else {
        festival = state.miniFestivals;
        festival.Records[index].movie_data.data.push(
          ...data?.Records[0]?.movie_data.data,
        );
        festival.Records[index].movie_data.pagination = {
          ...festival.Records[index].movie_data.pagination,
          ...data?.Records[0]?.movie_data.pagination,
        };
        festival.Records[index].currentPage =
          festival.Records[index].currentPage + 1;
      }
      return {
        ...state,
        miniFestivals: festival,
        miniFestivalsLoading: false,
      };
    case FETCH_MINI_FESTIVALS_ERROR:
      return {
        ...state,
        miniFestivals: null,
        miniFestivalsError: payload,
        miniFestivalsLoading: false,
      };
    case SET_SELECTED_MINI_FESTIVAL:
      return {
        ...state,
        selectedMiniFestival: payload,
      };
    default:
      return state;
  }
};

export default homepageReducer;
