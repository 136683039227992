import {
  FETCH_SEAT_LAYOUT,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SEAT_TYPE,
  FETCH_SEAT_TYPE_SUCCESS,
  FETCH_SEAT_TYPE_ERROR,
  SET_SEAT_SELECTED,
  CLEAR_SEAT_SELECTION,
  UPDATE_SEAT_LAYOUT,
  RESERVE_SEAT,
  RESERVE_SEAT_SUCCESS,
  RESERVE_SEAT_ERROR,
  SET_RESERVATION_ID,
  RELEASE_SEAT,
  RELEASE_SEAT_SUCCESS,
  RELEASE_SEAT_ERROR,
  SET_TEMP_RESERVATION_ID,
  SET_INTERNET_HANDLING_FEES_APPLIED,
} from './seatLayoutTypes';
import { groupBy } from 'lodash';
import { getValueFromGlobalOptions } from '@helper/utils';

let initialState = {
  seatLayout: null,
  seatLayoutLoading: false,
  seatLayoutError: null,
  seatType: null,
  seatTypeLoading: false,
  seatTypeError: null,
  seats_selected: [],
  reservation_id: null,
  reservation_idLoading: false,
  reservation_idError: null,
  reservation_id_temp: null,
  is_internet_handling_enable: false,
};

const seatLayoutReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_SEAT_SELECTION:
      state.seats_selected.map((s) => (s.SeatSelected = !s.SeatSelected));
      return {
        ...state,
        seats_selected: [],
      };

    case RESERVE_SEAT:
      return {
        ...state,
        reservation_id: null,
        reservation_idLoading: true,
      };
    case RESERVE_SEAT_ERROR:
      return {
        ...state,
        reservation_id: null,
        reservation_idError: payload,
      };
    case RESERVE_SEAT_SUCCESS:
      return {
        ...state,
        reservation_id: payload,
        reservation_idLoading: false,
      };
    case RELEASE_SEAT_SUCCESS:
      return {
        ...state,
        reservation_id: null,
      };
    case SET_TEMP_RESERVATION_ID: {
      return {
        ...state,
        reservation_id_temp: payload,
      };
    }
    case SET_INTERNET_HANDLING_FEES_APPLIED: {
      return {
        ...state,
        is_internet_handling_enable: payload,
      };
    }
    case 'FETCH_SEAT_LAYOUT_SUCCESS_ON_DEMAND':
      return {
        ...state,
        seat_layout_loading: false,
        seat_layout: action.payload,
        seat_layout_error: null,
        selectedTicketPrice: {
          priceInCents: 0,
          taxInCents: 0,
          totalWithFnb: 0,
          taxWithFnb: 0,
        },
      };
    default:
      return state;
  }
};

export default seatLayoutReducer;
