import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import SpecialInstructionsPopup from './SpecialInstructionsPopup.jsx';
import { useSelector } from 'react-redux';
import { currencyFormatter } from '../../helper/currencyFormatter';

const ScreeningCardNew = (props) => {
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const { t, setCardSelected, cardSelected } = props;

  const [specialInstructionsPopup, setSpecialInstructionsPopup] = useState(false);

  function assigner(screenNum, type) {
    if (type === 'name') {
      if (screenNum.booking_type_id === 2) {
        return t('Crowdsourced Screening');
      } else if (screenNum.booking_type_id === 1) {
        return t('Confirmed Screening');
      } else if (screenNum.booking_type_id === 3) {
        return t('Private Screening');
      } else {
        return t('Confirmed Screening');
      }
    } else if (type === 'desc') {
      if (screenNum.booking_type_id === 2) {
        return `${t('This screening will get confirmed when atleast')} ${
          screenNum.min_seats
        } ${t('seats have been booked')}`;
      } else if (screenNum.booking_type_id === 1) {
        return `${t(
          'No need to wait for the minimum no. of seats to sell. Book',
        )} ${screenNum.min_seats} ${t('seats and confirm immediately')}`;
      } else if (screenNum.booking_type_id === 3) {
        return `${t(
          'Book an exclusive private screening for your friends and family',
        )}`;
      } else {
        return `${t(
          'No need to wait for the minimum no. of seats to sell. Book',
        )} ${'25'} ${t('seats and confirm immediately')}`;
      }
    }
  }

  return (
    <>
      {/* popup for private screening type */}
      {/*
      <SpecialInstructionsPopup
        showPrivateScreenPopup={specialInstructionsPopup}
        onConfirmPrivateScreeningPopup={(log) => {
          console.log({log})
        }}
        closeFunc={() => setSpecialInstructionsPopup(false)}
      />
      */}
      <section className="row">
        <div className="col-12">
          <div className="screening-type-2">
            <div className="d-md-inline-flex justify-content-center">
              {props.screeningTypes?.map(
                (screen, index) =>
                  screen?.isAvailable && (
                    <article
                      key={index}
                      className={`mb-4 ${
                        screen?.booking_type_id === 2
                          ? 'green'
                          : screen?.booking_type_id === 3
                          ? 'yellow'
                          : ''
                      }`}
                      id={``}
                      // onClick={() => setSpecialInstructionsPopup(true)}
                    >
                      <div className="mb-4">
                        {/* <p className="popular">
                        {screen?.booking_type_id === 2 && t(`POPULAR`)}
                      </p> */}
                        <p className="screen-name">{assigner(screen, 'name')}</p>
                        {screen?.booking_type_id === 3
                          ? screen?.screen_total_seats && (
                              <p className="audi-cap">
                                {t('Auditorium Capacity')} -{' '}
                                {screen.screen_total_seats}
                              </p>
                            )
                          : screen?.booking_type_id === 2 && (
                              <p className="audi-cap">{t(`POPULAR`)}</p>
                            )}
                      </div>
                      <div className="">
                        {/* <p className="around">{t("Around")}</p> */}
                        <p className="around-price">
                          {curr_code} {currencyFormatter(screen?.amount)}
                        </p>
                      </div>
                      <p className="screen-desc">{assigner(screen, 'desc')}</p>

                      <button
                        id="forStageThree"
                        className={`blue-btn px-4 mx-auto ${
                          cardSelected && 'inactive'
                        }`}
                        onClick={() => {
                          setCardSelected(true);
                          props.passedFunc(screen.booking_type_id);
                        }}
                      >
                        {/*{t("common.Select")}*/}
                        Select
                      </button>
                    </article>
                  ),
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default withNamespaces()(ScreeningCardNew);
