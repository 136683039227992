import React, { Component, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import moment from 'moment';
import { PaymentService } from '@apiService/PaymentService';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import { SOCKET_URL } from '@config';
import { bookSeats, bookFnb } from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
// import { globalConfigContext } from '@context/GlobalConfigContext';
var wsUrl = SOCKET_URL;
var socket = new WebSocket(wsUrl);

const Checkout = (props) => {
  const dispatch = useDispatch();
  // const { arabic, currency } = useContext(globalConfigContext);
  const arabic = false;
  const { reservation_id } = props.match.params;
  const t = props.t;
  const allshowDetails = useSelector((state) => state.movies.show_times);
  const session_id = useSelector((state) => state.booking.session_id);
  // const showDetails = allshowDetails.find((show) => show.ss_id == session_id);
  const showDetails = useSelector((state) => state.movies.selected_session);
  const movies = useSelector((state) => state.movies.now_playing_movies);
  const movieDetails = movies.find(
    (movie) => movie.movie_id == showDetails.movie_id,
  );
  const seatsSelected = useSelector((state) => state.booking.seats_selected);
  const ft_id = useSelector((state) => state.booking.ft_id);
  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice,
  );
  const selectedFnbItems = useSelector((state) => state.booking.selected_fnb_items);
  const seats_string = useSelector((state) => state.booking.seats_string);
  const timer = useSelector((state) => state.booking.timer);
  const showTimeoutPopup = useSelector((state) => state.booking.showTimeoutPopup);
  const fnbPrice = useSelector((state) => state.foodAndBeverages.fnb_price);
  const history = useHistory();
  const onClickBack = () => {
    history.go(-1);
  };
  //
  const global = useSelector((state) => state.global);
  const {
    currency: { curr_code, curr_id },
    cinema: { cinema_id },
  } = global;

  socket.onmessage = function (data) {
    const message = JSON.parse(data);
    console.log({ message }, 'received');
  };
  const makePayment = async () => {};

  return (
    <div className="">
      <MovieHeader />
      <div className="bg-light-black">
        <Container fluid className="py-3 mb-4">
          <Row className="align-items-center py-3 mb-40">
            <Col>
              <div className="go_back">
                <div className="button_wrap" onClick={onClickBack}>
                  <div className="go_back_button"></div>
                </div>
                <h2 className="big_text">{t('Go Back')}</h2>
              </div>
            </Col>
            <Col className="text-right">
              <span className="pill kumbh bg-light-black-2 p-3">
                {timer.minutes}:{timer.seconds}
              </span>
            </Col>
          </Row>
          <Row className="">
            <Col xs={8} className="mx-auto">
              <Row className="mxh-70 bg-pb d-flex align-items-center py-3">
                <Col>
                  <p className="kumbh fw-bold text-center frs-24">
                    {t('CHECKOUT')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="px-0">
                  <div className="hero">
                    <div className="imgOverlay d-flex justify-content-center mxh-360">
                      <img
                        className=""
                        src={showDetails.md_thumbnail_url}
                        alt={showDetails.movie_title}
                      />
                    </div>
                    <div className="hero-text px-3 d-flex flex-column justify-content-end">
                      <Container fluid>
                        <Row className="pb-4">
                          <Col>
                            <Row className="pb-3">
                              <Col md={8}>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={showDetails.md_thumbnail_url}
                                    alt="title"
                                    height="160px"
                                  />
                                  <div className="d-flex flex-column justify-content-end pl-3 kumbh fw-reg frs-20">
                                    <p className="fw-bold frs-24">
                                      {movieDetails?.movie_title ||
                                        movieDetails?.original_title_of_movie}
                                    </p>
                                    <p className="mb-2">{movieDetails.mc_plot}</p>
                                    <p className="mb-2">
                                      {moment(showDetails.ss_start_date).format(
                                        'DD-MM-YY',
                                      )}
                                    </p>
                                    <p className="mb-2">
                                      {t('Seats')} {seats_string}
                                    </p>
                                    {/* <p className="">4D E-motion</p> */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="bgb py-5 mb-4">
                <Col xs={8} className="br-1">
                  <div className="px-3 frs-20 text-light kumbh fw-reg">
                    <p className="ls-2 fw-bold pb-3 bb-1 mb-3">
                      {t('Checkout Summary')}
                    </p>
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="">
                        {t('Tickets')} (x{seatsSelected.length})
                      </span>
                      <span className="fw-bold frs-24">
                        {curr_code}{' '}
                        {(
                          selectedTicketPrice.priceInCents -
                          selectedTicketPrice.totalWithFnb
                        ).toFixed(2)}
                      </span>
                    </div>
                    {selectedFnbItems.length > 0 && (
                      <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                        <span className="">
                          {t('Food and Beverages')} (x{selectedFnbItems.length})
                        </span>
                        <span className="fw-bold frs-24">
                          {curr_code} {selectedTicketPrice.totalWithFnb.toFixed(2)}
                        </span>
                      </div>
                    )}
                    {/* <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="frs-20">
                        {t("Taxes")}
                        <br /> */}
                    {/* <span className="blurred-white frxl-14 frl-12 frm-10 frs-8">
                          (8%)
                        </span> */}
                    {/* </span>
                      <span className="fw-bold frs-24">
                        {curr_code} {selectedTicketPrice.taxInCents}{" "}
                      </span>
                    </div> */}
                    {/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
                                            <span className="frs-20">Discount Applied</span>
                                            <span className="fw-bold frs-24">-15 SR</span>
                                        </div> */}
                    <div className="pb-3 d-flex justify-content-between bb-1">
                      <span className="frs-20">{t('Total')}</span>
                      <span className="fw-bold frs-24">
                        {curr_code} {selectedTicketPrice.priceInCents.toFixed(2)}{' '}
                      </span>
                    </div>

                    {/* <div className="mt-3 pb-3 d-flex justify-content-between bb-1">
                                            <span className="frs-20">QR Code</span>
                                            <img src={ip1} width="150" height="150" />
                                        </div> */}
                  </div>
                </Col>
              </Row>

              <Row className="mt-5 justify-content-center">
                <Col
                  lg={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <span
                    onClick={makePayment}
                    className="ls-3 frs-24 kumbh fw-bold px-4 blue-btn"
                  >
                    {t('MAKE PAYMENT')}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Modal
          show={showTimeoutPopup}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-time-up"
          backdrop="static"
        >
          <Modal.Header closeButton={false}>
            <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">
              {t('timeout.time-up')}
            </p>
          </Modal.Header>
          <Modal.Body>
            <p className="kumbh fw-reg frxl-24 frl-20 frs-16">
              {t('timeout.session-expired')}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <span
              onClick={() => history.push('/')}
              className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14"
            >
              {t('timeout.go-back')}
            </span>
          </Modal.Footer>
        </Modal>
      </div>

      {/* <MovieHeader />
      <div className="">
        <Container fluid className="py-3 mb-4">
          <Row className="d-flex justify-content-center my-5 ">
            <Col xs={6}>
              <h1 className="fw-bold text-center">
                {t("checkout.Your booking is successful, enjoy the show!")}
              </h1>
            </Col>
          </Row>
          <Row className="fnb-checkout">
            <Col xs={8} className="mx-auto">
              <Row className="mxh-70 bg-pb d-flex align-items-center py-3">
                <Col>
                  <p className="fw-bold text-center frs-24">
                    {t("checkout.YOUR TICKET")}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="px-0">
                  <div className="hero">
                    <div className="imgOverlay d-flex justify-content-center">
                      <img className="bg-img" src={ip1} />
                    </div>
                    <div className="hero-text px-3 d-flex flex-column justify-content-end">
                      <Container fluid>
                        <Row className="pb-4">
                          <Col>
                            <Row className="pb-3">
                              <Col xs={8}>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={ip1}
                                    alt="title"
                                    className="moviePoster"
                                  />
                                  <div
                                    className={`d-flex flex-column justify-content-end fw-reg frs-12 ${
                                      arabic ? "pr-3" : "pl-3"
                                    }`}
                                  >
                                    <p className="fw-bold frs-18">
                                      {movieDetails.Title}
                                    </p>
                                    <p className="mb-2">
                                      {movieDetails.cinema_name}
                                    </p>
                                    <p className="mb-2">
                                      {moment(movieDetails.Showtime).format(
                                        "DD-MM-YY"
                                      )}
                                    </p>
                                    <p className="mb-2">
                                      {t("fnbconfirm.Seats")}{" "}
                                      {movieDetails.seats_name}
                                    </p>
                                    <p className="">
                                      {movieDetails.SessionAttribute}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xs={4}
                                className="d-flex flex-column justify-content-end align-items-end frs-12 fw-reg text-center"
                              >
                                <img src={ip1} className="qr" />
                                <p className="">
                                  {t("checkout.Scan this code and recieve")}
                                </p>
                                <p className="">
                                  {t("checkout.the ticket on your phone")}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="bgb py-5 mb-4">
                <Col xs={8} className="br-1">
                  <div className="px-3 frs-20 fw-bold">
                    <p className="ls-2 pb-3">{t("checkout.Order Details")}</p>
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="blurred-text">
                        {t("checkout.Tickets")} {seatsSelected.toString()}
                      </span>
                      <span className="frs-24">
                        {selectedTicketPrice.priceInCents / 100} SAR
                      </span>
                    </div>
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="blurred-text">
                        {t("checkout.Food and Beverages")} (x
                        {selectedFnbItems.length})
                      </span>
                      <span className="frs-24">
                        {fnbPrice.priceInCents / 100} SR
                      </span>
                    </div>
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="blurred-text">
                        {t("checkout.Taxes")}
                        <br />
                        <span className="blurred-text-2">(15%)</span>
                      </span>
                      <span className="frs-24">
                        {(selectedTicketPrice.taxInCents +
                          fnbPrice.taxesInCents) /
                          100}{" "}
                        SR
                      </span>
                    </div>
                    {/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
                                            <span className="blurred-text">Discount Applied</span>
                                            <span className="fw-bold frs-24">-15 SR</span>
                                        </div>
                    <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
                      <span className="blurred-text">
                        {t("checkout.Booking Fee")}
                      </span>
                      <span className="frs-24">
                        {(selectedTicketPrice.priceInCents +
                          fnbPrice.priceInCents +
                          selectedTicketPrice.taxInCents +
                          fnbPrice.taxesInCents) /
                          100}{" "}
                        SAR
                      </span>
                    </div>
                    <div className="pb-3 d-flex justify-content-between bb-1">
                      <span className="blurred-text">
                        {t("collect_ticket.Total")}
                      </span>
                      <span className="frs-24">
                        {(selectedTicketPrice.priceInCents +
                          fnbPrice.priceInCents +
                          selectedTicketPrice.taxInCents +
                          fnbPrice.taxesInCents) /
                          100}{" "}
                        SAR
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="h-100 w-100 d-flex flex-column justify-content-between frs-28 ml-4 fw-bold">
                    <span>
                      <p className="frs-16 blurred-text">
                        {t("checkout.Booking Date")}
                      </p>
                      <p>
                        {moment(showDetails.transaction_date_time).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </span>
                    <span>
                      <p className="frs-16 blurred-text">
                        {t("checkout.Booking Time")}
                      </p>
                      <p>
                        {moment(showDetails.transaction_date_time).format(
                          "h:mm A"
                        )}
                      </p>
                    </span>
                    <span>
                      <p className="frs-16 blurred-text">
                        {t("checkout.Payment Method")}
                      </p>
                      <p>{t("checkout.Credit Card")}</p>
                    </span>
                    <span>
                      <p className="frs-16 blurred-text">
                        {t("checkout.Ticket ID")}
                      </p>
                      <p>{showDetails.bookingId}</p>
                    </span>
                  </div>
                </Col>
              </Row>

              <Row className="mt-5 justify-content-center">
                <Col className="text-center">
                  <span className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn">
                    {t("checkout.PRINT TICKET")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>*/}
    </div>
  );
};

export default withNamespaces()(Checkout);
