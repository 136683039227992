export const FETCH_SEAT_LAYOUT_LOADING = 'FETCH_SEAT_LAYOUT_LOADING';
export const FETCH_SEAT_LAYOUT_SUCCESS = 'FETCH_SEAT_LAYOUT_SUCCESS';
export const FETCH_SEAT_LAYOUT_ERROR = 'FETCH_SEAT_LAYOUT_ERROR';
export const FETCH_SHOW_DETAILS_SUCCESS = 'FETCH_SHOW_DETAILS_SUCCESS';
export const SET_SEAT_SELECTED = 'SET_SEAT_SELECTED';
export const SET_RESERVATION_ID = 'SET_RESERVATION_ID';
export const SET_SEAT_SELECT_ERROR = 'SET_SEAT_SELECT_ERROR';
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export const SET_TRANSACTION_ERROR = 'SET_TRANSACTION_ERROR';
export const CLEAR_BOOKING_STATE = 'CLEAR_BOOKING_STATE';
export const SET_TICKET_PRICE = 'SET_TICKET_PRICE';
export const RESET_RESERVATION_ID = 'RESET_RESERVATION_ID';
export const SET_GUEST_ID = 'SET_GUEST_ID';
export const START_TIMER = 'START_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const SET_BOOKING_FEE_DATA = 'SET_BOOKING_FEE_DATA';
export const SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_FOOD_AND_BEVERAGES_STATE = 'CLEAR_FOOD_AND_BEVERAGES_STATE';
export const CLEAR_SELECTED_SEATS = 'CLEAR_SELECTED_SEATS';
export const SET_SEATS_STRING = 'SET_SEATS_STRING';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_PRICE = 'SET_PRICE';
export const SELECT_FNB_ITEM = 'SELECT_FNB_ITEM';
export const FETCH_FNB_ITEMS = 'FETCH_FNB_ITEMS';
export const FETCH_FNB_ITEMS_ERROR = 'FETCH_FNB_ITEMS_ERROR';
export const CLEAR_FNB_STATE = 'CLEAR_FNB_STATE';
export const SET_BOOKING_ID = 'SET_BOOKING_ID';
export const SET_BOOKING_CODE = 'SET_BOOKING_CODE';
export const PICKUP_TICKET_LOADING = 'PICKUP_TICKET_LOADING';
export const PICKUP_TICKET_SUCCESS = 'PICKUP_TICKET_SUCCESS';
export const PICKUP_TICKET_ERROR = 'PICKUP_TICKET_ERROR';
export const RESET_PICKUP_TICKET_ERROR = 'RESET_PICKUP_TICKET_ERROR';
export const CLEAR_STORE = 'CLEAR_STORE';
export const FETCH_SEAT_TYPE_LOADING = 'FETCH_SEAT_TYPE_LOADING';
export const FETCH_SEAT_TYPE_SUCCESS = 'FETCH_SEAT_TYPE_SUCCESS';
export const FETCH_SEAT_TYPE_ERROR = 'FETCH_SEAT_TYPE_ERROR';
export const ON_SET_COMPLETE_RATE_CARD = 'ON_SET_COMPLETE_RATE_CARD';
export const SET_FT_ID = 'SET_FT_ID';
export const ON_SET_COMPLETE_NEW_TOTAL_AMOUNT = 'ON_SET_COMPLETE_NEW_TOTAL_AMOUNT';
export const SET_PAYMENT_MODES = 'SET_PAYMENT_MODES';
export const SET_BOOKING_DETAILS = 'SET_BOOKING_DETAILS';
export const SET_FNB_LOADER = 'SET_FNB_LOADER';
export const CLEAR_SELECTED_FNB_ITEMS = 'CLEAR_SELECTED_FNB_ITEMS';
export const SET_FNB_BOOKING_DETAILS = 'SET_FNB_BOOKING_DETAILS';
export const FETCH_VOUCHERS = 'FETCH_VOUCHERS';
export const FETCH_VOUCHERS_SUCCESS = 'FETCH_VOUCHERS_SUCCESS';
export const FETCH_VOUCHERS_ERROR = 'FETCH_VOUCHERS_ERROR';
export const VERIFY_VOUCHER = 'VERIFY_VOUCHER';
export const VERIFY_VOUCHER_SUCCESS = 'VERIFY_VOUCHER_SUCCESS';
export const VERIFY_VOUCHER_ERROR = 'VERIFY_VOUCHER_ERROR';
export const PICKUP_TICKET_CONTENT_SUCCESS = 'PICKUP_TICKET_CONTENT_SUCCESS';
export const UPDATE_SEAT_LAYOUT = 'UPDATE_SEAT_LAYOUT';
