export const FETCH_GLOBAL = 'FETCH_GLOBAL';
export const FETCH_GLOBAL_SUCCESS = 'FETCH_GLOBAL_SUCCESS';
export const FETCH_GLOBAL_ERROR = 'FETCH_GLOBAL_ERROR';

export const FETCH_GLOBAL_OPTIONS = 'FETCH_GLOBAL_OPTIONS';
export const FETCH_GLOBAL_OPTIONS_SUCCESS = 'FETCH_GLOBAL_OPTIONS_SUCCESS';
export const FETCH_GLOBAL_OPTIONS_ERROR = 'FETCH_GLOBAL_OPTIONS_ERROR';

export const FETCH_CINEMAS = 'FETCH_CINEMAS';
export const FETCH_CINEMAS_SUCCESS = 'FETCH_CINEMAS_SUCCESS';
export const FETCH_CINEMAS_ERROR = 'FETCH_CINEMAS_ERROR';

export const FETCH_CURRENCY = 'FETCH_CURRENCY';
export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS';
export const FETCH_CURRENCY_ERROR = 'FETCH_CURRENCY_ERROR';

export const SELECT_CINEMA_SUCCESS = 'SELECT_CINEMA_SUCCESS';
export const SELECT_CINEMA_ERROR = 'SELECT_CINEMA_ERROR';

export const SET_KIOSK_SELECTED_LANGUAGE = 'SET_KIOSK_SELECTED_LANGUAGE';
export const SET_CHECKOUT_TIMER = 'SET_CHECKOUT_TIMER';
export const SET_SIGNAGE_SCREEN = 'SET_SIGNAGE_SCREEN';
export const SET_SCREEN_TIME = 'SET_SCREEN_TIME';
export const SET_IMAGE_CHANGE_TIME = 'SET_IMAGE_CHANGE_TIME';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR';

export const SET_CUST_MOB_CODE = 'SET_CUST_MOB_CODE';
export const SET_BOOKING_TYPE = 'SET_BOOKING_TYPE';
export const SET_ON_DEMAND = 'SET_ON_DEMAND';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const CINEMA_SETTINGS = 'CINEMA_SETTINGS';
export const CINEMA_SETTINGS_ERROR = 'CINEMA_SETTINGS_ERROR';
export const DUMMY_IMAGE = 'DUMMY_IMAGE';
export const SET_DEVICE_CODE = 'SET_DEVICE_CODE';
