import React, { useEffect, useState, useRef } from 'react';
import MovieHeader from '@components/partials/MovieHeader';
import ip1 from '../assets/imgs/ip1.jpg';
import bgticket from '../assets/imgs/ticket-bg.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { getPrintTicket, clearStore } from '@store/booking/bookingActions';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Spinner,
  Alert,
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useHistory } from 'react-router-dom';
import { BASEURL } from '../config';
import momentT from 'moment-timezone';
import { getLangSpecificAttribute } from '../helper/Languages';

function PrintTicketComponent({ ticketHTML }) {
  return (
    <div dangerouslySetInnerHTML={{ __html: ticketHTML ? ticketHTML : '' }}></div>
  );
}

const KioskCollectTwo = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let result, Additional_Charges;
  let final_ticket_price = 0;
  const pickup_ticket = useSelector((state) => state.booking.pickup_ticket);
  const pickup_ticket_content = useSelector(
    (state) => state.booking.pickup_ticket_content,
  );
  if (pickup_ticket) {
    result = pickup_ticket.result;
    Additional_Charges = pickup_ticket.Additional_Charges;
    result = result[0];
    result.seats_types.forEach((seat) => {
      final_ticket_price += seat.seatPrice;
    });
  } else {
    history.push('/');
  }

  const global = useSelector((state) => state.global);
  const {
    globalOptions,
    cinema: { cinema_id },
    currency: { curr_code },
    selected_language: { lang_id },
    dummy_image,
  } = global;
  // const printTicket = (booking_id) =>
  //   dispatch(getPrintTicket({ booking_id, cinema_id }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const completeTransaction = () => {
    dispatch(clearStore());
    history.push(`/`);
  };

  const [ticketHTML, setTicketHTML] = useState(null);
  const ticketReceiptRef = useRef();

  const printTicket = async (booking_id) => {
    const ticket_HTML = await dispatch(getPrintTicket({ booking_id, cinema_id }));
    setTicketHTML(ticket_HTML);
    initPrintTicketReceipt();
  };

  const initPrintTicketReceipt = useReactToPrint({
    content: () => ticketReceiptRef.current,
  });

  const t = props.t;
  return (
    <div className="">
      <MovieHeader />

      <div className="bg-light-black position-relative">
        <div className="absolute-overlay"></div>
        <div ref={ticketReceiptRef} className="receipt-container">
          <PrintTicketComponent ticketHTML={ticketHTML} />
        </div>
        <Container fluid className="py-3 mb-4 z-index-500 position-relative">
          <Row className="my-5">
            <Col className="text-center">
              <h1 className="kumbh fw-bold">
                {t('checkout.Your booking is successful,')}
                <br /> {t('checkout.enjoy the show!')}
              </h1>
            </Col>
          </Row>

          <Row className="">
            <Col xs={8} className="mx-auto">
              <Row className="mxh-70 bg-pb d-flex align-items-center py-3">
                <Col>
                  <p className="kumbh fw-bold text-center frs-24">
                    {t('checkout.YOUR TICKET')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="px-0">
                  <div className="hero">
                    <div className="imgOverlay d-flex justify-content-center mxh-360">
                      <img
                        className=""
                        src={
                          getLangSpecificAttribute(
                            pickup_ticket_content,
                            lang_id,
                            'artwork',
                          ) || dummy_image
                        }
                      />
                    </div>
                    <div className="hero-text px-3 d-flex flex-column justify-content-end">
                      <Container fluid>
                        <Row className="pb-4">
                          <Col>
                            <Row className="pb-3">
                              <Col md={7}>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      getLangSpecificAttribute(
                                        pickup_ticket_content,
                                        lang_id,
                                        'artwork',
                                      ) || dummy_image
                                    }
                                    alt="title"
                                    height="160px"
                                  />
                                  <div className="d-flex flex-column justify-content-end pl-3 kumbh fw-reg frs-20">
                                    <p className="fw-bold frs-24">
                                      {getLangSpecificAttribute(
                                        pickup_ticket_content,
                                        lang_id,
                                        'mc_title',
                                      )}{' '}
                                      ({result && result.mf_name})(
                                      {result && result.lang_name})
                                    </p>
                                    <p className="">{result && result.cine_name}</p>
                                    <p className="">
                                      {moment(
                                        result && result.ss_start_date_time,
                                      ).format('LL')}{' '}
                                      {moment(
                                        result && result.ss_start_show_time,
                                        'HH:mm',
                                      ).format('HH:mm')}
                                    </p>
                                    <p className="">
                                      {t('checkout.Seats')}{' '}
                                      {result?.seats && result.seats.toString()}
                                    </p>
                                    <p className="">
                                      {result && result.screen_name}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col md={5} className="text-right">
                                <div className="d-flex flex-column align-items-end">
                                  {result && (
                                    <QRCode
                                      includeMargin={true}
                                      value={`${BASEURL}/your-ticket/${result?.sb_booking_code}`}
                                    />
                                  )}

                                  <p className="kumbh fw-reg lh-1 mt-2">
                                    {t(
                                      'checkout.Scan this code and receive the ticket on your phone',
                                    )}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="bgb py-5 mb-4">
                <Col xs={8} className="br-1">
                  <div className="px-3 frs-20 text-light kumbh fw-reg">
                    <p className="ls-2 fw-bold pb-3 bb-1 mb-3">
                      {t('checkout.Order Details')}
                    </p>
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="">
                        {t('checkout.Tickets')} (x
                        {result?.seats && result.seats.length})
                      </span>
                      <span className="fw-bold frs-24">
                        {curr_code} {result?.sb_total_net_amount.toFixed(2)}
                      </span>
                    </div>
                    {/* <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                        <span className="">
                          {t("Food and Beverages")} (x{selectedFnbItems.length})
                        </span>
                        <span className="fw-bold frs-24">
                          {curr_code}{" "}
                          {selectedTicketPrice.totalWithFnb.toFixed(2)}
                        </span>
                      </div> */}
                    <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="frs-20">
                        {t('checkout.Taxes')}
                        <br />
                        {/* <span className="blurred-white frs-14">
                          (15%)
                        </span> */}
                      </span>
                      <span className="fw-bold frs-24">
                        {curr_code}{' '}
                        {(
                          result?.sb_total_amount - result?.sb_total_net_amount
                        ).toFixed(2)}
                      </span>
                    </div>
                    {/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
                                            <span className="frs-20">Discount Applied</span>
                                            <span className="fw-bold frs-24">-15 SR</span>
                                        </div> */}
                    <div className="pb-3 d-flex justify-content-between bb-1">
                      <span className="frs-20">{t('checkout.Total')}</span>
                      <span className="fw-bold frs-24">
                        {curr_code} {result && result.sb_total_amount.toFixed(2)}
                      </span>
                    </div>

                    {/* <div className="mt-3 pb-3 d-flex justify-content-between bb-1">
                                            <span className="frs-20">QR Code</span>
                                            <img src={ip1} width="150" height="150" />
                                        </div> */}
                  </div>
                </Col>
                <Col xs={4} className="">
                  <div className="px-1 kumbh fw-reg frs-20">
                    <div className="d-flex flex-column mt-3 mb-5">
                      <span className="blurred-white">{t('Show Date')}</span>
                      <span className="fw-bold frs-26">
                        {/* {moment(result && result.sb_booking_date_time).format(
                          "DD/MM/YYYY"
                        )} */}
                        {moment(result && result.ss_start_date_time).format(
                          'DD/MM/YYYY',
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-column my-5">
                      <span className="blurred-white">{t('Show Time')}</span>
                      {/* <span className="fw-bold frs-26">
                        {moment(result && result.sb_booking_date_time).format(
                          "hh:mm A"
                        )}
                      </span> */}
                      <span className="fw-bold frs-26">
                        {/* {result && result.tz_name
                          ? moment(result && result.sb_booking_date_time)
                              .tz(result.tz_name)
                              .format("LT")
                          : moment(
                              result && result.sb_booking_date_time
                            ).format("LT")} */}
                        {/* {moment(result?.transaction_date_time).format("HH:mm")} */}
                        {moment(
                          result && result.ss_start_show_time,
                          'HH:mm',
                        ).format('HH:mm')}
                      </span>
                    </div>
                    <div className="d-flex flex-column my-5">
                      <span className="blurred-white">
                        {t('checkout.Payment Method')}
                      </span>
                      <span className="fw-bold frs-26">
                        {result && result.pm_payment_mode}
                      </span>
                    </div>
                    <div className="d-flex flex-column mt-5">
                      <span className="blurred-white">
                        {t('checkout.Booking ID')}
                      </span>
                      <span className="fw-bold frs-26">
                        {result && result.sb_booking_code}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              {false && (
                <Row className="mt-5 justify-content-center">
                  <Col
                    lg={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span
                      className="ls-3 frs-24 kumbh fw-bold px-4 blue-btn"
                      onClick={(e) => printTicket(result && result.sb_id)}
                    >
                      {t('checkout.PRINT TICKET')}
                    </span>
                  </Col>
                </Row>
              )}
              <Row className="mt-5 justify-content-center">
                <Col
                  lg={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  <span
                    className="ls-3 frs-24 kumbh fw-bold px-4 blue-btn"
                    onClick={(e) => completeTransaction()}
                  >
                    {t('checkout.COMPLETE TRANSACTION')}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withNamespaces()(KioskCollectTwo);
