import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
// import "../assets/css/fnb.css";
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import order from '../assets/imgs/order.jpg';
import snack from '@assets/imgs/svgs/snacks_default.svg';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import { titleCase } from '@helper/formatting';
import GoBack from '@components/partials/GoBack';
import MovieHeader from '@components/partials/MovieHeader';
import TimeoutContainer from '@components/partials/TimeoutContainer';
import { withNamespaces } from 'react-i18next';

import moment from 'moment';
const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function FnbConfirm({ t, props }) {
  // const { arabic, currency } = useContext(globalConfigContext);
  const arabic = 'false';

  return (
    <>
      {/* <MovieHeader />
			<Container className="fnb-confirm">
				<div className="go_back">
				<div className="button_wrap">
				<div className="go_back_button"></div>
				</div>
				<h2 className="big_text">GO BACK</h2>
				</div>
				<Row>
				<Col md={8} className="kumbh">
				<div className="fnb-ticket">
				<h3 className="heading">TICKET</h3>
				<div className="d-flex pt-4">
					<img src={ip1} className="img-fluid" />
					<div className="pl-4">
					<h4 className="font-weight-bold">Bad Boys For Life(2D, EN)</h4>
					<div className="flex-between">
					<div>
					<p>Date</p>
					<h5>24th January, 2021</h5>
					</div>
					<div>
					<p>Seats</p>
					<h5>G4, G5</h5>
					</div>
					<div>
					<p>Show Details</p>
					<h5>MACRO XE, 4:30PM</h5>
					</div>
					<div>
					<p>Voucher Code</p>
					<h5>ABCDS (20% OFF)</h5>
					</div>
					</div>
					</div>
				</div>
				</div>
				<div className="fnb-order py-5">
				<h3 className="heading">F&B ORDER</h3>
				<div className="flex-between">
					{Array.from(Array(5), (e, i) => (
					<div className="p-3">
					<img src={order} className="img-fit" />
					<h6 className="pt-3">Large Popcorn + Large Cake</h6>
					<h6 className="blurred-white">240KCal</h6>
					</div>
					))}
				</div>

				</div>
				</Col>
				<Col md={4}>
				<div className="fnb-total">
				<div className="item bb-1">
					<h4>Tickets</h4>
					<div className="flex-center">
					<h5>G4, G5 (Macro XE)</h5>
					<h6>25 SR</h6>
					</div>
				</div>
				<div className="item bb-1">
					<h4>Add-ons</h4>
					{Array.from(Array(5), (e, i) => (
					<div className="flex-center">
					<h5>Large Popcorn + Large Coke</h5>
					<h6>10 SR</h6>
					</div>
					))}
				</div>
				<div className="item bb-1">
					<div className="flex-center">
					<h5>Taxes</h5>
					<h6>10 SR</h6>
					</div>
				</div>
				<div className="item">
					<div className="flex-center">
					<h5>Total</h5>
					<h6>75 SR</h6>
					</div>
				</div>
				</div>
				</Col>
				</Row>
			</Container> */}

      <MovieHeader />
      {/* <TimeoutContainer t={t} session_id={session_id} cinema_id={cinema_id} /> */}
      <Container fluid className="fnb-confirm">
        <GoBack to="prev" />
        <Row>
          <Col md={8} className="kumbh">
            <div className="fnb-ticket">
              <h3 className="heading">{t('fnbconfirm.TICKET')}</h3>
              <div className="d-flex pt-4 pb-4">
                <img src={movieDef} className="mtp" />
                <div className="pl-4">
                  <h3 className="fw-bold lh-1">Bad Boys For Life(2D, EN)</h3>
                  <div className="flex-between">
                    <div>
                      <p>{t('fnbconfirm.Date')}</p>
                      <h4>24th January, 2021</h4>
                    </div>
                    <div>
                      <p>{t('fnbconfirm.Seats')}</p>
                      <h4>G4, G5</h4>
                    </div>
                    <div>
                      <p>{t('fnbconfirm.Show Details')}</p>
                      <h4>MACRO XE, 06:54 PM</h4>
                    </div>
                    <div>
                      <p>{t('fnbconfirm.Voucher Code')}</p>
                      <h4>ABCDS (20% OFF)</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fnb-order py-3">
              <h3 className="heading">{t('fnbconfirm.F&B ORDER')}</h3>
              <div className="flex-between">
                {Array.from(Array(5), (e, i) => (
                  <div className="p-3 d-flex flex-column">
                    <img src={snack} className="fci" />
                    <span className="frs-14 fw-reg py-1">
                      Large Popcorn + Large Cake
                    </span>
                    <span className="blurred-white frs-14 fw-reg">240KCal</span>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="fnb-total">
              <div className="item bb-1">
                <h5 className="blurred-white">{t('fnbconfirm.Tickets')}</h5>
                <div className="d-flex justify-content-between w-100">
                  <h5 className="pr-2">PREMIUM</h5>
                  <h4 className="pl-2">25 SR</h4>
                </div>
              </div>
              <div className="item bb-1">
                <h5 className="blurred-white">{t('fnbconfirm.Add-ons')}</h5>
                {Array.from(Array(5), (e, i) => (
                  <div className="w-100 d-flex justify-content-between">
                    <h5 className="pr-2">Large Popcorn + Large Coke</h5>
                    <h4 className="pl-2">10 SR</h4>
                  </div>
                ))}
              </div>
              <div className="item bb-1">
                <div className="d-flex justify-content-between">
                  <h5 className="pr-2">{t('fnbconfirm.Taxes')}</h5>
                  <h4 className="pl-2">10 SR</h4>
                </div>
              </div>
              <div className="item bb-1">
                <div className="d-flex justify-content-between">
                  <h5 className="pr-2">{t('fnbconfirm.Booking Fee')}</h5>
                  <h4 className="pl-2">10 SR</h4>
                </div>
              </div>
              <div className="item">
                <div className="d-flex justify-content-between">
                  <h5 className="pr-2">{t('fnbconfirm.Total')}</h5>
                  <h4 className="pl-2">25 SR</h4>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* <Row className="align-items-center bgb mt-5 sticky-movie-bar">
				<Col md={12} className="seat-footer">
					<Row>
						<Col md={8}>
							<div className="d-flex br-1 py-4 px-3">
								<div>
									<h6 className="f-grey">Total Payable</h6>
									<h4 className="fw-bold">
										{(fnbPrice.priceInCents +
											modifierPrice.priceInCents +
											selectedTicketPrice.priceInCents) /
											100}{' '}
										SR
									</h4>
								</div>
							</div>
						</Col>
						<Col md={3} className="flex-center">
							<button className="blue-btn btn-lg btn-block flex-center" onClick={onProceed}>
								{showProceedSpinner ? (
									<Spinner animation="border" role="status" className="spinner">
										<span className="sr-only">Loading...</span>
									</Spinner>
								) : (
									t('common.PROCEED')
								)}
							</button>
						</Col>
					</Row>
				</Col>
			</Row> */}

        <Row className="sticky-bar-margin">
          <Col className="bt-1 pt-4 mt-5">
            <div className="w-100 d-flex flex-column align-items-center">
              <p className="frs-36 fw-bold pb-4">
                {t('fnb_page.Enter your contact details')}
              </p>
              <input
                className="kiosk-input"
                placeholder={t('collect_login.placeholders.email')}
                type="email"
              />
              {false && (
                <span className="form-error-text d-block">
                  {t('collect_login.errors.Enter a valid email address')}
                </span>
              )}
              <div className="d-flex align-items-end">
                <Dropdown className="code mr-4">
                  <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
                    COUNTRY
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdownItems">
                    <Dropdown.Item className="">COUNTRY NAME</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <input
                  className="kiosk-input"
                  placeholder={t('collect_login.placeholders.mobile')}
                  type="number"
                />
              </div>
              {false && (
                <span className="form-error-text d-block">
                  {t('collect_login.errors.Enter a valid 9 digit mobile number')}
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Row className="align-items-center bgb mt-5 sticky-movie-bar">
          <Col xs={12} className="seat-footer">
            <Row className="py-4">
              <Col md={8}>
                <div className={`d-flex px-3 ${arabic ? 'bl-1' : 'br-1'}`}>
                  <div>
                    <h4 className="f-grey">{t('fnbconfirm.Total Payable')}</h4>
                    <h1 className="fw-bold">100 SR</h1>
                    <h5>Taxes: 10 SR</h5>
                  </div>
                  <div className={`flex-center ${arabic ? 'pr-5' : 'pl-5'}`}>
                    <h6 className="custom-label">Total Bill</h6>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center"
              >
                <span className="blue-btn btn-lg px-5">
                  {t('fnbconfirm.CONFIRM & PAY')}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
// const MovieHeader = () => {
//  return (
//   <div className="movie_header">
//    <div className="logo"></div>
//    <h2 className="big_text">OASIS MALL</h2>
//    <div className="dropdown_div">
//     <select className="dropdown" name="language" id="language">
//      <option value="english">ENGLISH</option>
//      <option value="arabic">ARABIC</option>
//     </select>
//    </div>
//   </div>
//  );
// };

export default withNamespaces()(FnbConfirm);
