import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import screen from '../assets/imgs/svgs/screen.svg';
import { useReactToPrint } from 'react-to-print';
import {
  fetchShowDetails,
  getPrintTicket,
  clearStore,
  printTicket as printTicketAction,
} from '@store/booking/bookingActions';
import ip1 from '../assets/imgs/ip1.jpg';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import moment from 'moment';
import MovieHeader from '@components/partials/MovieHeader';
import QRCode from 'qrcode.react';
import { BASEURL } from '@config';
import { getLangSpecificAttribute } from '../helper/Languages';

function PrintTicketComponent({ ticketHTML }) {
  return (
    <div
      style={{ backgroundColor: 'white ' }}
      dangerouslySetInnerHTML={{ __html: ticketHTML ? ticketHTML : '' }}
    ></div>
  );
}

const FinalTicket = (props) => {
  const booking_id = useSelector((state) => state.booking.booking_id);
  const bookingCode = useSelector((state) => state.booking.bookingCode);
  const booking_type = useSelector((state) => state.movies.booking_type);
  const privateTotalPrice = useSelector((state) => state.movies.totalAmount);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchShowDetails({ bookingCode }));
  }, [bookingCode, dispatch]);
  const t = props.t;
  const history = useHistory();

  const showDetails = useSelector((state) => state.booking.show_details);
  let private_rate_card_detail = showDetails?.private_rate_card_detail ?? [];

  console.log(
    '🚀 ~ file: FinalTicket.js ~ line 35 ~ FinalTicket ~ showDetails',
    showDetails,
  );

  const redirectToHome = () => {
    history.push('/');
  };
  const global = useSelector((state) => state.global);
  const {
    cinema: { cinema_id },
    currency: { curr_code },
    mode,
    selected_language: { lang_id },
    dummy_image,
  } = global;

  // const printTicket = () => dispatch(getPrintTicket({ booking_id, cinema_id }));

  const completeTransaction = () => {
    dispatch(clearStore());
    history.push(`/`);
  };
  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice,
  );
  const successfulVoucherCode = useSelector(
    (state) => state.booking.successfulVoucherCode,
  );
  const voucherAmount = useSelector((state) => state.booking.voucherAmount);
  const selectedFnbItems = useSelector((state) => state.booking.selected_fnb_items);
  const bookingDetails = useSelector((state) => state.booking.bookingDetails);
  const fnbBookingDetails = useSelector((state) => state.booking.fnbBookingDetails);

  const [ticketHTML, setTicketHTML] = useState(null);
  const ticketReceiptRef = useRef();

  const printTicket = async () => {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      dispatch(
        printTicketAction({
          cinema_id,
          showBookingId: bookingDetails.bookingID,
          baseurl: BASEURL,
        }),
      );
    } else {
      const ticket_HTML = await dispatch(getPrintTicket({ booking_id, cinema_id }));
      setTicketHTML(ticket_HTML);
      initPrintTicketReceipt();
    }
  };

  const initPrintTicketReceipt = useReactToPrint({
    content: () => ticketReceiptRef.current,
    copyStyles: false, // re-use current styles
  });

  const calculateFnbQuantity = (items) => {
    let count = 0;
    items.map((x) => {
      count += parseInt(x.Quantity);
    });
    return count;
  };

  return (
    <div className="">
      <MovieHeader />
      <div className="bg-light-black position-relative">
        <div className="absolute-overlay"></div>
        <div ref={ticketReceiptRef} className="receipt-container">
          <PrintTicketComponent ticketHTML={ticketHTML} />
        </div>
        <Container fluid className="py-3 mb-4 z-index-500 position-relative">
          <Row className="my-5">
            <Col className="text-center">
              <h1 className="kumbh fw-bold">
                {t('checkout.Your booking is successful,')}
                <br /> {t('checkout.enjoy the show!')}
              </h1>
            </Col>
          </Row>

          <Row className="">
            <Col xs={8} className="mx-auto">
              {mode !== 2 && (
                <>
                  <Row className="mxh-70 bg-pb d-flex align-items-center py-3">
                    <Col>
                      <p className="kumbh fw-bold text-center frs-24">
                        {t('checkout.YOUR TICKET')}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-0">
                      <div className="hero">
                        <div className="imgOverlay d-flex justify-content-center mxh-360">
                          <img
                            className=""
                            src={
                              getLangSpecificAttribute(
                                showDetails.languageSpecificImages,
                                lang_id,
                                'artwork',
                              ) || dummy_image
                            }
                            alt={getLangSpecificAttribute(
                              showDetails.languageSpecificImages,
                              lang_id,
                              'mc_title',
                            )}
                          />
                        </div>
                        <div className="hero-text px-3 d-flex flex-column justify-content-end">
                          <Container fluid>
                            <Row className="pb-4">
                              <Col>
                                <Row className="pb-3">
                                  <Col md={7}>
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          getLangSpecificAttribute(
                                            showDetails.languageSpecificImages,
                                            lang_id,
                                            'artwork',
                                          ) || dummy_image
                                        }
                                        alt="title"
                                        height="160px"
                                      />
                                      <div className="d-flex flex-column justify-content-end pl-3 kumbh fw-reg frs-20">
                                        <p className="fw-bold frs-24">
                                          {getLangSpecificAttribute(
                                            showDetails.languageSpecificImages,
                                            lang_id,
                                            'mc_title',
                                          )}
                                        </p>
                                        <p className="">{showDetails.cine_name}</p>
                                        <p className="">
                                          {moment(showDetails.show_date).format(
                                            'dddd, MMMM Do, YYYY',
                                          )}{' '}
                                          {moment(
                                            showDetails.show_time,
                                            'HH:mm',
                                          ).format('HH:mm')}
                                        </p>
                                        <p className="">
                                          {t('checkout.Seats')}{' '}
                                          {showDetails.seats &&
                                            showDetails.seats.toString()}
                                        </p>
                                        {/* <p className="">4D E-motion</p> */}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={5} className="text-right">
                                    <div className="d-flex flex-column align-items-end">
                                      <QRCode
                                        includeMargin={true}
                                        value={`${BASEURL}/your-ticket/${bookingCode}`}
                                      />

                                      <p className="kumbh fw-reg lh-1 mt-2">
                                        {t(
                                          'checkout.Scan this code and receive the ticket on your phone',
                                        )}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {mode === 2 && (
                <Row>
                  <Col>
                    <Container fluid>
                      <Col ml={5} md={12} className="text-right">
                        <div className="d-flex flex-column align-items-end">
                          <QRCode
                            includeMargin={true}
                            value={`${BASEURL}/your-fnb-ticket/${fnbBookingDetails?.orderCode}`}
                          />
                          <p className="kumbh fw-reg">
                            {t(
                              'checkout.Scan this code and receive the ticket on your phone',
                            )}
                          </p>
                        </div>
                      </Col>
                    </Container>
                  </Col>
                </Row>
              )}

              <Row className="bgb py-5 mb-4">
                <Col xs={8} className="br-1">
                  <div className="px-3 frs-20 text-light kumbh fw-reg">
                    <p className="ls-1 fw-bold pb-3 bb-1 mb-3">
                      {t('checkout.Order Details')}
                    </p>
                    {mode !== 2 && booking_type != 3 && (
                      <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                        <span className="">
                          {t('checkout.Tickets')} (x
                          {showDetails.seats && showDetails.seats.length})
                        </span>
                        <span className="fw-bold frs-24">
                          {curr_code}{' '}
                          {booking_type == 3
                            ? (
                                privateTotalPrice -
                                selectedTicketPrice.totalWithFnb +
                                voucherAmount
                              ).toFixed(2)
                            : (
                                selectedTicketPrice.priceInCents -
                                selectedTicketPrice.totalWithFnb +
                                voucherAmount
                              ).toFixed(2)}
                        </span>
                      </div>
                    )}
                    {selectedFnbItems.length > 0 && (
                      <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                        <span className="">
                          {t('checkout.Food and Beverages')} (x
                          {calculateFnbQuantity(selectedFnbItems)})
                        </span>
                        <span className="fw-bold frs-24">
                          {curr_code} {selectedTicketPrice.totalWithFnb.toFixed(2)}
                        </span>
                      </div>
                    )}
                    {!!private_rate_card_detail?.length &&
                      !!private_rate_card_detail[0]?.slab.length && (
                        <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                          <span className="">
                            {t('checkout.Tickets')} (x
                            {private_rate_card_detail[0].customSeatCount})
                          </span>
                          <span className="fw-bold frs-24">
                            {curr_code}{' '}
                            {private_rate_card_detail[0].customSeatCount *
                              private_rate_card_detail[0]?.slab?.[0]?.price.toFixed(
                                2,
                              )}
                          </span>
                        </div>
                      )}
                    {!!private_rate_card_detail?.length &&
                      !!private_rate_card_detail[0]?.fnb.length && (
                        <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                          <span className="">
                            {t('Concessions')} (x
                            {private_rate_card_detail[0].customSeatCount})
                          </span>
                          <span className="fw-bold frs-24">
                            {curr_code}{' '}
                            {private_rate_card_detail[0].customSeatCount *
                              private_rate_card_detail[0]?.fnb?.[0]?.price.toFixed(
                                2,
                              )}
                          </span>
                        </div>
                      )}
                    {!!private_rate_card_detail?.length &&
                      !!private_rate_card_detail[0]?.addon.length && (
                        <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                          <span className="">
                            {t('Add Ons')} (x
                            {private_rate_card_detail[0]?.addon?.[0]?.quantity})
                          </span>
                          <span className="fw-bold frs-24">
                            {curr_code}{' '}
                            {private_rate_card_detail[0]?.addon?.[0]?.quantity *
                              private_rate_card_detail[0]?.addon?.[0]?.price.toFixed(
                                2,
                              )}
                          </span>
                        </div>
                      )}
                    {/* <div className="d-flex justify-content-between pb-3 bb-1 mb-3">
                      <span className="frs-20">
                        {t("Taxes")}
                        <br />
                        <span className="blurred-white frs-14">
                          (15%)
                        </span>
                      </span>
                      <span className="fw-bold frs-24">
                        {(showDetails.fnb_taxes_in_cents +
                          showDetails.ticket_taxes_in_cents) /
                          100}{" "}
                        SR
                      </span>
                    </div> */}
                    {successfulVoucherCode && (
                      <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
                        <span className="frs-20">{t('Voucher Applied')}</span>
                        <span className="fw-bold frs-24">
                          - {curr_code} {voucherAmount.toFixed(2)}
                        </span>
                      </div>
                    )}
                    <div className="pb-3 d-flex justify-content-between bb-1">
                      <span className="frs-20">{t('checkout.Total')}</span>
                      <span className="fw-bold frs-24">
                        {curr_code}{' '}
                        {booking_type == 3
                          ? private_rate_card_detail[0]?.total.toFixed(2)
                          : selectedTicketPrice.priceInCents.toFixed(2)}{' '}
                      </span>
                    </div>

                    {/* <div className="mt-3 pb-3 d-flex justify-content-between bb-1">
                                            <span className="frs-20">QR Code</span>
                                            <img src={ip1} width="150" height="150" />
                                        </div> */}
                  </div>
                </Col>
                <Col xs={4} className="">
                  <div className="px-1 kumbh fw-reg frs-20">
                    <div className="d-flex flex-column mt-3 mb-5">
                      <span className="blurred-white">{t('Show Date')}</span>
                      <span className="fw-bold frs-26">
                        {/* {moment(showDetails.sb_booking_date_time).format(
                          "DD/MM/YYYY"
                        )} */}
                        {moment(showDetails.show_date).format('DD/MM/YYYY')}
                      </span>
                    </div>
                    <div className="d-flex flex-column my-5">
                      <span className="blurred-white">{t('Show Time')}</span>
                      {/* <span className="fw-bold frs-26">
                        {moment(showDetails.sb_booking_date_time).format(
                          "hh:mm A"
                        )}
                      </span> */}
                      <span className="fw-bold frs-26">
                        {/* {moment(showDetails.transaction_date_time).format(
                          "HH:mm"
                        )} */}
                        {moment(showDetails.show_time ?? moment(), 'HH:mm').format(
                          'HH:mm',
                        )}
                      </span>
                    </div>
                    <div className="d-flex flex-column my-5">
                      <span className="blurred-white">
                        {t('checkout.Payment Method')}
                      </span>
                      {mode === 2 ? (
                        <span className="fw-bold frs-26">
                          {fnbBookingDetails?.paymentMode?.pm_payment_mode}
                        </span>
                      ) : (
                        <span className="fw-bold frs-26">
                          {showDetails.pm_payment_mode}
                        </span>
                      )}
                    </div>
                    <div className="d-flex flex-column mt-5">
                      <span className="blurred-white">
                        {t('checkout.Booking ID')}
                      </span>
                      {mode === 2 ? (
                        <span className="fw-bold frs-26">
                          {fnbBookingDetails?.orderCode}
                        </span>
                      ) : (
                        <span className="fw-bold frs-26">
                          {showDetails.sb_booking_code}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              {false && mode !== 2 && (
                <Row className="mt-5 justify-content-center">
                  <Col
                    lg={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <span
                      className="ls-3 frs-24 kumbh fw-bold px-4 blue-btn"
                      onClick={(e) => printTicket()}
                    >
                      {t('checkout.PRINT TICKET')}
                    </span>
                  </Col>
                  {/* <Col
                  lg={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <span
                    className="ls-3 frs-24 kumbh fw-bold px-4 blue-btn"
                    onClick={(e) => completeTransaction()}
                  >
                    Complete Transaction
                  </span>
                </Col> */}
                </Row>
              )}
              <Row className="mt-5 justify-content-center">
                <Col
                  lg={10}
                  className="d-flex justify-content-center align-items-center"
                >
                  <span
                    className="ls-3 frs-24 kumbh fw-bold px-4 blue-btn"
                    onClick={(e) => completeTransaction()}
                  >
                    {t('checkout.COMPLETE TRANSACTION')}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
// const MovieHeader = () => {
//     const changeLanguage = (lng) => {
//         let stylesSheets = document.styleSheets;
//         let lastStyleSheet = document.styleSheets[stylesSheets.length - 1];
//         if (lng === "ar") {
//             require("../assets/css/arabic.css");
//             lastStyleSheet.disabled = false;
//             document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
//         } else {
//             lastStyleSheet.disabled = true;
//             document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
//         }
//         i18n.changeLanguage(lng);
//     };
//     return (
//         <div className="movie_header">
//             <div className="logo"></div>
//             <h2 className="big_text">OASIS MALL</h2>
//             <div className="dropdown_div">
//                 <select onChange={(e) => changeLanguage(e.target.value)} className="dropdown" name="language" id="language">
//                     <option value="en">ENGLISH</option>
//                     <option value="ar">ARABIC</option>
//                 </select>
//             </div>
//         </div>
//     );
// };

export default withNamespaces()(FinalTicket);
