import {
  FETCH_GLOBAL,
  FETCH_GLOBAL_SUCCESS,
  FETCH_GLOBAL_ERROR,
  FETCH_GLOBAL_OPTIONS,
  FETCH_GLOBAL_OPTIONS_SUCCESS,
  FETCH_GLOBAL_OPTIONS_ERROR,
  FETCH_CINEMAS,
  FETCH_CINEMAS_SUCCESS,
  FETCH_CINEMAS_ERROR,
  SELECT_CINEMA_SUCCESS,
  SELECT_CINEMA_ERROR,
  FETCH_CURRENCY,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_ERROR,
  SET_KIOSK_SELECTED_LANGUAGE,
  SET_SIGNAGE_SCREEN,
  SET_SCREEN_TIME,
  SET_IMAGE_CHANGE_TIME,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
  SET_CUST_MOB_CODE,
  SET_BOOKING_TYPE,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  CINEMA_SETTINGS,
  CINEMA_SETTINGS_ERROR,
  DUMMY_IMAGE,
  SET_DEVICE_CODE,
  SET_ON_DEMAND,
} from './globalTypes';

const initialState = {
  loading: false,
  error: null,
  globalSettings: {},
  globalOptions: [],
  cinemas: [],
  cinema: {},
  signage: [],
  screen_time: null,
  image_change_time: null,
  currency: {},
  selected_language:
    JSON.parse(localStorage.getItem('kiosk_selected_language')) || null,
  countries: [],
  cust_mob_code: '',
  mode: parseInt(localStorage.getItem('kiosk_mode')) || null,
  isOnDemandActive: localStorage.getItem('is_on_demand_active') === 'true' || false,
  isAuthenticated: false,
  cinema_settings: {},
  dummy_image: null,
  uniqueDeviceCode: null,
};

const globalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
    case LOGIN_ERROR:
    case LOGOUT:
      return { ...state, isAuthenticated: false };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SET_BOOKING_TYPE:
      return { ...state, mode: payload };
    case FETCH_GLOBAL:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GLOBAL_SUCCESS:
      return {
        ...state,
        loading: false,
        globalSettings: payload,
        error: null,
      };
    case FETCH_GLOBAL_ERROR:
      return {
        loading: false,
        globalSettings: {},
        error: payload,
      };
    case FETCH_GLOBAL_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GLOBAL_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        globalOptions: payload,
        error: null,
      };
    case FETCH_GLOBAL_OPTIONS_ERROR:
      return {
        loading: false,
        globalOptions: [],
        error: payload,
      };
    case FETCH_CINEMAS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CINEMAS_SUCCESS:
      return {
        ...state,
        loading: false,
        cinemas: payload,
        error: null,
      };
    case FETCH_CINEMAS_ERROR:
      return {
        loading: false,
        cinemas: [],
        error: payload,
      };
    case CINEMA_SETTINGS:
      return {
        ...state,
        cinema_settings: payload,
      };
    case DUMMY_IMAGE:
      return {
        ...state,
        dummy_image: payload,
      };
    case CINEMA_SETTINGS_ERROR:
      return {
        ...state,
        cinema_settings: {},
        error: payload,
      };
    case FETCH_CURRENCY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currency: payload,
        error: null,
      };
    case FETCH_CURRENCY_ERROR:
      return {
        loading: false,
        currency: {},
        error: payload,
      };
    case SELECT_CINEMA_SUCCESS:
      return {
        ...state,
        loading: false,
        cinema: payload,
        error: null,
      };
    case SELECT_CINEMA_ERROR:
      return {
        loading: false,
        cinema: {},
        error: payload,
      };
    case SET_CUST_MOB_CODE:
      return {
        ...state,
        loading: false,
        cust_mob_code: payload,
        error: null,
      };
    case SET_SIGNAGE_SCREEN:
      return {
        ...state,
        loading: false,
        signage: payload,
        error: null,
      };
    case SET_SCREEN_TIME:
      return {
        ...state,
        loading: false,
        screen_time: payload,
        error: null,
      };
    case SET_IMAGE_CHANGE_TIME:
      return {
        ...state,
        loading: false,
        image_change_time: payload,
        error: null,
      };
    case SET_KIOSK_SELECTED_LANGUAGE:
      localStorage.setItem(`kiosk_selected_language`, JSON.stringify(payload));
      return {
        ...state,
        selected_language: payload,
      };
    case FETCH_COUNTRIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: payload,
        error: null,
      };
    case FETCH_COUNTRIES_ERROR:
      return {
        loading: false,
        countries: [],
        error: payload,
      };
    case SET_DEVICE_CODE: {
      return {
        ...state,
        uniqueDeviceCode: action.payload,
      };
    }
    case SET_ON_DEMAND: {
      return {
        ...state,
        isOnDemandActive: action.payload,
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
