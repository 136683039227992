import React, {
  FunctionComponent,
  useState,
  useEffect,
  MutableRefObject,
} from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { toast } from 'react-toastify';
import frenchLayout from 'simple-keyboard-layouts/build/layouts/french';
import englishLayout from 'simple-keyboard-layouts/build/layouts/english';
import germanLayout from 'simple-keyboard-layouts/build/layouts/german';
import italianLayout from 'simple-keyboard-layouts/build/layouts/italian';
import { useDispatch, useSelector } from 'react-redux';
interface IProps {
  onChange: (input: string) => void;
  keyboardRef: MutableRefObject<Keyboard>;
}

const KeyboardWrapper: FunctionComponent<IProps> = ({
  onChange,
  keyboardRef,
  enableDigitValidation = false,
  keyboardOptions,
  showKeyboardMobile = undefined,
  setShowKeyboardMobile = (f) => f,
  setShowKeyboard = (f) => f,
  setshowSearchKeyboard = (f) => f,
  setShowKeyboardVoucher = (f) => f,
}) => {
  const [layoutName, setLayoutName] = useState('default');
  const [layout, setLayout] = useState(englishLayout);
  const global = useSelector((state) => state.global);
  const {
    globalOptions,
    cinema: { cinema_name },
    selected_language: { lang_name },
  } = global || {};
  const onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    }

    if (button === '{enter}') {
      setShowKeyboardMobile(false);
      setShowKeyboard(false);
      setshowSearchKeyboard(false);
      setShowKeyboardVoucher(false);
    }
  };
  useEffect(() => {
    switch (lang_name) {
      case 'English':
        setLayout(englishLayout);
        break;
      case 'German':
        setLayout(germanLayout);
        break;
      case 'Italian':
        setLayout(italianLayout);
        break;
      case 'French':
        setLayout(frenchLayout);
        break;
      default:
        setLayout(englishLayout);
    }
  }, [lang_name]);
  return (
    <Keyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      layoutName={layoutName}
      onChange={onChange}
      onKeyPress={onKeyPress}
      layout={layout}
      {...keyboardOptions}
      onRender={() => console.log('Rendered')}
    />
  );
};

export default KeyboardWrapper;
