import React, { useEffect, useState, useRef, useContext } from 'react';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
import { useParams } from 'react-router';
import { withNamespaces } from 'react-i18next';
import close from '@assets/imgs/svgs/close.svg';
import moment from 'moment';
import dummyPoster from '@assets/imgs/trolls.png';
import { useDispatch, useSelector } from 'react-redux';
import { getLangSpecificAttribute } from '../../helper/Languages';
import { currencyFormatter } from '../../helper/currencyFormatter';
import { setPrivateScreeningPayload } from '../../store/seatLayout/seatLayoutActions';

const checkValidSelection = ({ fnb, slab, isFnbCompulsory, total }) => {
  if (total <= 0) return false;
  if (!slab?.quantity) return false;
  if (isFnbCompulsory && !fnb?.quantity) return false;

  return true;
};

const getFnbTotal = ({ fnb, isFnbCompulsory, customSeatCount }) => {
  if (!isFnbCompulsory && !fnb?.quantity) return 0;

  if (customSeatCount > 0) return customSeatCount * (fnb?.price ?? 0);

  return (fnb?.quantity ?? 0) * (fnb?.price ?? 0);
};

const getSlabTotal = ({ slab, isTicketSlab, customSeatCount }) => {
  if (!isTicketSlab && !slab?.quantity) return 0;

  if (customSeatCount > 0) return customSeatCount * (slab?.price ?? 0);

  return (slab?.quantity ?? 0) * (slab?.price ?? 0);
};

const SpecialInstructionsPopup = ({
  t,
  showPrivateScreenPopup,
  onConfirmPrivateScreeningPopup,
  closeFunc,
}) => {
  const { category_id } = useParams();
  const [specialRequest, setSpecialRequest] = useState('');
  const [isTotalValid, setTotalIsValid] = useState(false);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      className="seat-type-popup"
      centered
      onHide={() => closeFunc(false)}
      show={showPrivateScreenPopup}
    >
      <section className="wrapper container-fluid">
        <img
          src={close}
          className="close-btn-main"
          onClick={() => closeFunc(false)}
          alt=""
        />
        <section className="row">
          <div className="col-12 payment-booking-details">
            <PaymentBookingDetails
              category_id={category_id}
              t={t}
              setTotalIsValid={setTotalIsValid}
            />
          </div>
        </section>
        <>
          <div className="row">
            <div className="px-3 question">
              <p>{t('Have a special request or instruction')}</p>
            </div>
          </div>

          <div className="row">
            <div className="px-3 w-100">
              <input
                type="text"
                className="answer"
                placeholder={t('Type Here...')}
                value={specialRequest}
                onChange={(e) => setSpecialRequest(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row align-items-center flex-wrap">
            <button
              disabled={!isTotalValid}
              className={`blue-btn px-5 mx-auto ${!isTotalValid && 'inactive'}`}
              onClick={() => onConfirmPrivateScreeningPopup(specialRequest)}
            >
              {t('Confirm')}
            </button>
            {/* <p className="my-3 my-md-0">OR</p>
            <button
              disabled={!isTotalValid}
              className={`blue-btn mx-auto ${!isTotalValid && 'inactive'}`}
              onClick={() => onConfirmPrivateScreeningPopup(specialRequest)}
            >
              {t("Skip")}
            </button> */}
          </div>
        </>
      </section>
    </Modal>
  );
};

export default withNamespaces()(SpecialInstructionsPopup);

//Start Payment Booking Details
const PaymentBookingDetails = ({ category_id, t, setTotalIsValid }) => {
  let dispatch = useDispatch();
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const booking_type = useSelector((state) => state.movies.booking_type);
  const appliedVoucher = useSelector((state) => state?.promotions?.applied_voucher);
  const discount = useSelector((state) => state?.promotions?.voucher_discount);
  const selected_seats = useSelector((state) => state.seatLayout.seats_selected);
  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id, lang_name, iso_2 } = selected_language;

  const screen = screeningTypes.find((screen) => screen.booking_type_id === 3);

  const [seats, setSeats] = useState(0);
  let [total, setTotal] = useState(0);
  let [slab, setSlab] = useState(null);
  let [fnb, setFnb] = useState(null);
  let [addon, setAddon] = useState(null);

  let [privateRateCardDetails] = screen?.rate_card_array;

  let isTicketSlab = privateRateCardDetails.isTicketSlab === 'Y';
  let isFnbCompulsory = privateRateCardDetails.isFnbCompulsory === 'Y';

  const updateSlab = (slab) => {
    setSlab(slab);
  };
  const updateFnb = (fnb) => {
    setFnb(fnb);
  };
  const updateAddon = (e, addon) => {
    setAddon(e.target.checked ? addon : null);
  };

  const updateCustomSeatCount = (seatCount, type = 'add') => {
    if (
      (type === 'add' &&
        (seats + seatCount >= screen.screen_total_seats ||
          seats + seatCount <= 0)) ||
      (type !== 'add' && (seatCount >= screen.screen_total_seats || seatCount <= 0))
    )
      return;

    // get neatest highest slab for custom seat count tobe updated slab to it
    let slabsAvail = privateRateCardDetails.rateCardArray
      .filter((s) => s.quantity >= (type === 'add' ? seats + seatCount : seatCount))
      .sort((a, b) => a.quantity - b.quantity);

    // if not nearest highest slot get slot with highest quantity available
    if (!slabsAvail.length)
      slabsAvail = privateRateCardDetails.rateCardArray.sort(
        (a, b) => b.quantity - a.quantity,
      );

    updateSlab(slabsAvail[0]);
    setSeats(type === 'add' ? seats + seatCount : seatCount);
  };

  useEffect(() => {
    if (privateRateCardDetails?.rateCardArray?.length === 1)
      updateSlab(privateRateCardDetails?.rateCardArray[0]);
  }, [privateRateCardDetails.rateCardArray]);

  useEffect(() => {
    let fnbFiltered = null;

    // fnbOptions form data pricing for current selected fnb
    let fnbOptions = privateRateCardDetails.fnbDataPricing
      .filter((f) => fnb?.item_id === f?.item_id)
      .sort((a, b) => a.quantity - b.quantity);

    // fnb options which are 1:1 to ticketSlab by quantity value
    let fnbOptionsBySlab = fnbOptions.filter((f) => slab?.quantity === f?.quantity);

    // if fnb/ticketSlab pair with quantity exist
    if (fnbOptionsBySlab.length) {
      let [fnbMapped] = fnbOptionsBySlab; // fnbOptions
      fnbFiltered = fnbMapped;

      console.log({
        fnbPriceForTotal: (fnbMapped.price ?? 0) * (fnbMapped.quantity ?? 0),
        price: fnbMapped.price,
        quantity: fnbMapped.quantity,
        fnbMapped,
      });
    } else {
      // here because no fnb items mapped to ticket slab by quantity
      // then for each fnbDataPricing check nearest lower fnbDataPricing object
      let nearestFnbOption = null;
      fnbOptions.forEach((f, i) => {
        if (f.quantity <= slab?.quantity) nearestFnbOption = f;
      });

      fnbFiltered = nearestFnbOption ?? fnbOptions[0];

      console.log({
        fnbPriceForTotal:
          (nearestFnbOption?.price ?? 0) * (nearestFnbOption?.quantity ?? 0),
        price: nearestFnbOption?.price,
        quantity: nearestFnbOption?.quantity,
        nearestFnbOption,
      });
    }

    console.log('fnbOptions', fnbOptions);

    let total =
      (addon?.price ?? 0) +
      getSlabTotal({
        slab,
        isTicketSlab,
        customSeatCount: seats > 0 ? seats : slab?.quantity,
      }) +
      getFnbTotal({
        fnb: fnbFiltered,
        isFnbCompulsory,
        customSeatCount: seats > 0 ? seats : slab?.quantity,
      });

    setTotalIsValid(
      checkValidSelection({ fnb: fnbFiltered, slab, isFnbCompulsory, total }),
    );
    setTotal(total);

    dispatch(
      setPrivateScreeningPayload({
        customSeatCount: seats > 0 ? seats : slab?.quantity,
        isTicketSlab: privateRateCardDetails.isTicketSlab,
        isFnbCompulsory: privateRateCardDetails.isFnbCompulsory,
        total,
        slab,
        fnb: fnbFiltered,
        addon,
      }),
    );
  }, [
    seats,
    addon,
    fnb,
    privateRateCardDetails.isFnbCompulsory,
    privateRateCardDetails.isTicketSlab,
    // payload,
    privateRateCardDetails.fnbDataPricing,
    setTotalIsValid,
    slab,
    dispatch,
    isTicketSlab,
  ]);

  return (
    <>
      <div className="header">
        <div className="left text-center">
          <img
            src={
              getLangSpecificAttribute(
                scheduleDetails?.languageSpecificImages,
                lang_id,
                'artwork',
              ) || dummyPoster
            }
            alt="Movie Img"
            className="movie-img"
          />
        </div>
        <div className="right">
          <div>
            <h6 className="title mb-1">
              {getLangSpecificAttribute(
                scheduleDetails?.altMovieContent,
                lang_id,
                'mc_title',
              ) || scheduleDetails?.movie_title}
              , {moment(scheduleDetails?.mrrdr_release_date).format('YYYY')}
            </h6>

            <div className="features">
              <p className="mb-1">
                {scheduleDetails?.lang_name} {scheduleDetails?.mf_name}
              </p>
              <p className="mb-1">
                {scheduleDetails?.mrrdr_runtime} {t('common.mins')}{' '}
              </p>
              <p className="mb-1">
                {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}
              </p>
              <p className="mb-1">
                {moment(scheduleDetails?.ss_actual_start_date_time).format('ll')} |{' '}
                {moment(scheduleDetails?.ss_start_show_time, 'HH:mm:ss').format(
                  'HH:mm',
                )}
              </p>
              <div className="movie-rating">
                <p className="fs-13">
                  {t('Rating')}&nbsp;:&nbsp;
                  <span className="value small-font">
                    {scheduleDetails?.rating}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Header */}

      <div className="middle">
        <div>
          <p>
            {/*{t("Cinema")}:{" "}*/}
            {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
          </p>
          <p>
            {/*{scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}*/}
            {/* {scheduleDetails?.cine_location} */}
          </p>
        </div>
        <div>
          <p>
            {/*{t("Date")} & {t("Time")}:{" "}*/}
            {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
          </p>
          <p>
            {/*{moment(scheduleDetails?.ss_actual_start_date_time).format("ll")} |{" "}
            {moment(scheduleDetails?.ss_start_show_time, "HH:mm:ss").format(
              "HH:mm"
            )}*/}
          </p>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-2">
          <p className="mr-2">Seats</p>
          {isTicketSlab ? (
            <div className="d-flex align-items-center ml-2 flex-wrap">
              {privateRateCardDetails.rateCardArray.map((s, index) => (
                <button
                  key={s.rcg_c_id}
                  className={`select-btn ${
                    slab && slab.rcg_c_id === s.rcg_c_id && 'selected'
                  }`}
                  onClick={() => {
                    updateSlab(s);
                  }}
                >
                  {s.quantity}
                </button>
              ))}
            </div>
          ) : (
            <aside className="modifier-btn-2 ml-2">
              <span
                className={`mod-remove ${seats <= 1 ? 'inactive' : ''}`}
                onClick={() => {
                  if (seats <= 1) return;
                  updateCustomSeatCount(-1, 'add');
                }}
              >
                {`-`}
              </span>

              <span className="">
                <input
                  type="number"
                  placeholder={t('Number of seats')}
                  value={seats}
                  max={screen?.screen_total_seats}
                  onChange={(e) => {
                    updateCustomSeatCount(parseInt(e.target.value), 'set');
                  }}
                />
              </span>

              <span
                className={`mod-add ${
                  seats >= screen?.screen_total_seats ? 'inactive' : ''
                }`}
                onClick={() => {
                  if (seats >= screen?.screen_total_seats) return;
                  updateCustomSeatCount(1, 'add');
                }}
              >
                {`+`}
              </span>
            </aside>
          )}
        </div>

        <p className="mb-2">Combo Selection (Select only one) :</p>

        {privateRateCardDetails.fnbListing.map((f, i) => (
          <div className="checkbox-row" key={i}>
            <p className="mr-2">{`
                ${f.item_name} 
                ${
                  seats > 0
                    ? `x(${seats})`
                    : slab?.quantity
                    ? `(x${slab?.quantity})`
                    : ''
                }
                `}</p>
            <label className="ml-2 custom-check">
              <input
                type="radio"
                name="{f.item_name}"
                id={f.item_name}
                // checked={isFnbCompulsory ? fnb.fnb_private_id === f.fnb_private_id : false}
                onClick={() => updateFnb(f)}
              />
              <span />
            </label>
          </div>
        ))}

        <p className="mb-2">Add Ons (Select only one) :</p>
        {privateRateCardDetails.addOnsData.map((a, i) => (
          <div className="checkbox-row" key={a.item_name}>
            <p className="mr-2">{a.item_name}</p>
            <label className="ml-2 custom-check">
              <input
                type="checkbox"
                name="addOn"
                id={a.item_name}
                onClick={(e) => updateAddon(e, a)}
              />
              <span />
            </label>
          </div>
        ))}

        <div className="d-flex justify-content-between align-items-center pb-2">
          <p className="fs-16">
            {t('final_ticket_page.Tickets')} (
            {booking_type === 3
              ? slab?.quantity || 'All'
              : scheduleDetails?.ticket_count}
            ):{' '}
          </p>
          <p className="fs-22">
            {curr_code}{' '}
            {currencyFormatter(
              // slab.quantity * slab.price
              total,
            )}
          </p>
        </div>
        {discount && (
          <div className="d-flex justify-content-between align-items-center">
            <p className="fs-16">
              {t('Promocode Discount')}:
              <br />({appliedVoucher?.voucher_title})
            </p>
            <p className="fs-22">
              - {curr_code} {currencyFormatter(discount)}
            </p>
          </div>
        )}
      </div>
      {/* End Middle */}

      <div className="d-flex justify-content-between footer">
        <div>
          <p className="fs-16">{t('Total Amount Payable')}: </p>
          <p className="fs-10 text-grey font-italic">
            ({t('Amount inclusive of all taxes')})
          </p>
        </div>
        <p className="fs-22 font-weight-bold">
          {curr_code}{' '}
          {currencyFormatter(
            // (slab.quantity * slab.price)
            total - (discount ? discount : 0),
          )}
        </p>
      </div>

      {/* End Footer */}
    </>
  );
};
